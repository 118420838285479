import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsService } from 'src/app/services/services.index';
import { PromiseComponent } from '../promise/promise.component';
import { CarService } from '../car/car.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-car-issue',
  templateUrl: './car-issue.component.html',
  styleUrls: ['./car-issue.component.css'],
  providers: [CarService]
})
export class CarIssueComponent {

  formIssue:FormGroup = Object.create(null);
  url:string = '';
  provider:string = '';
  data:any = [];
  neighborhoods:any = [];
  backgroundFemale:string = '#d4d4d4';
  colorFemale:string = '#ffffff';
  backgroundMale:string = '#d4d4d4';
  colorMale:string = '#ffffff';
  error:boolean = false;
  serieError:boolean = false;
  engineError:boolean = false;
  platesError:boolean = false;
  nameError:boolean = false;
  lastError:boolean = false;
  secondError:boolean = false;
  addressError:boolean = false;
  emailError:boolean = false;
  phoneError:boolean = false;
  rfcError:boolean = false;
  curpError:boolean = false;
  sexError:boolean = false;
  neighborhoodError:boolean = false;
  loader:boolean = false;
  regime:any = [];
  tipoPersona:any = [];
  regimefiscal:any = [];
  regimeError:boolean = false;
  razonError:boolean = false;


  constructor(
    public _router: Router,
    public _settings: SettingsService,
    public _api: PromiseComponent,
    private fb: FormBuilder,
    public _services: CarService,


  ) {
    window.scrollTo(0, 0);
    this.data = this._settings['settings'];
    this.provider = this.data['selected_quote']['aseguradora'];
    console.log(this.data);
            
    switch(this.provider){
      case 'ana':
        this.url = environment.URL_ANA;
        this.formIssue = this.fb.group({
          empresa: ['gpi', Validators.required],

          cotizacion_id: [ this.data['selected_quote']['raw_response']['id'], Validators.required],
          motor: ['', Validators.required],
          serie: ['', Validators.required],
          placas: ['', Validators.required],
          nombre: [this.data['quote']['nombre'], Validators.required],
          paterno: [this.data['quote']['apellido_paterno'], Validators.required],
          materno: [this.data['quote']['apellido_materno'], Validators.required],
          calle: ['', Validators.required],
          fecha_nacimiento: [this.data['quote']['fecha_nacimiento'], Validators.required],
          numeroexterior: ['', Validators.required],
          numerointerior: ['', Validators.required],
          colonia: ['', Validators.required],
          cp: [this.data['quote']['cp'], Validators.required],
          tipopersona: ['1', Validators.required],
          correo: [this.data['quote']['correo'], Validators.required],
          telefono: [this.data['quote']['telefono'], Validators.required],
          rfc: ['', Validators.required],
          curp: ['', Validators.required],
        });

        this.tipoPersona = [
          // {id: 0, name: 'Selecciona el tipo de persona'},
          {id: 1, name: 'Fisica'},
          // {id: 2, name: 'Moral'},
        ]

        this.getNeighborhoodByZipAna()

        break;
      case 'chubb':
        this.url = environment.URL_CHUBB;
        this.formIssue = this.fb.group({
          aut_no_serie: ['', Validators.required],
          aut_no_motor: ['', Validators.required],
          aut_placas: ['', Validators.required],
          cotizacion_id: [ this.data['selected_quote']['raw_response']['id'], Validators.required],
          cte_apemat: [this.data['quote']['apellido_materno'], Validators.required],
          cte_apepat: [this.data['quote']['apellido_paterno'], Validators.required],
          cte_nombre: [this.data['quote']['nombre'], Validators.required],
          dir_calle_num: ['', Validators.required],
          fecha_nacimiento: [this.data['quote']['fecha_nacimiento'], Validators.required],
          email: [this.data['quote']['correo'], Validators.required],
          empresa: ['gpi', Validators.required],
          rfc: ['', Validators.required],
          sexo: ['', Validators.required],
          tel_num: [this.data['quote']['telefono'], Validators.required]
        });
        break;
      case 'gnp':
        this.url = environment.URL_GNP;
        this.formIssue = this.fb.group({
          empresa: ['gpi', Validators.required],

          cotizacion_id: [ this.data['selected_quote']['raw_response']['id'], Validators.required],
          placas: ['', Validators.required],
          motor: ['', Validators.required],
          serie: ['', Validators.required],
          rfc: ['', Validators.required],
          nombre: [this.data['quote']['nombre'], Validators.required],
          apellido_paterno: [this.data['quote']['apellido_paterno'], Validators.required],
          apellido_materno: [this.data['quote']['apellido_materno'], Validators.required],
          sexo: [this.data['quote']['sexo'], Validators.required],

          estado_civil: ['S', Validators.required],

          calle: ['', Validators.required],
          numero_exterior: ['', Validators.required],
          numero_interior: ['', Validators.required],
          colonia: ['', Validators.required],
          numero_telefono: [this.data['quote']['telefono'].substr(3, 7), Validators.required],
          email: [this.data['quote']['correo'], Validators.required],
          lada: [this.data['quote']['telefono'].substr(0, 3), Validators.required],
          regimen_fiscal: [0, Validators.required],
          // regimen_fiscal: [this.regimefiscal[0]['id'], Validators.required],
          razon_social: ['', Validators.required],
          tipo_via: ['AV', Validators.required],
        });

        this.regimefiscal = [
          { "CLAVE": 0, "NOMBRE": "Selecciona un régimen fiscal", "VALOR": 0 },
          { "CLAVE": 622, "NOMBRE": "Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras", "VALOR": 622 },
          { "CLAVE": 606, "NOMBRE": "Arrendamiento", "VALOR": 606 },
          { "CLAVE": 609, "NOMBRE": "Consolidación", "VALOR": 609 },
          { "CLAVE": 624, "NOMBRE": "Coordinados", "VALOR": 624 },
          { "CLAVE": 629, "NOMBRE": "De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales", "VALOR": 629 },
          { "CLAVE": 608, "NOMBRE": "Demás ingresos", "VALOR": 608 },
          { "CLAVE": 630, "NOMBRE": "Enajenación de acciones en bolsa de valores", "VALOR": 630 },
          { "CLAVE": 601, "NOMBRE": "General de Ley Personas Morales", "VALOR": 601},
          { "CLAVE": 628, "NOMBRE": "Hidrocarburos","VALOR": 628},
          { "CLAVE": 621, "NOMBRE": "Incorporación Fiscal","VALOR": 621},
          { "CLAVE": 611, "NOMBRE": "Ingresos por Dividendos (socios y accionistas)","VALOR": 611},
          { "CLAVE": 614, "NOMBRE": "Ingresos por intereses","VALOR": 614},
          { "CLAVE": 623, "NOMBRE": "Opcional para Grupos de Sociedades","VALOR": 623},
          { "CLAVE": 612, "NOMBRE": "Personas Físicas con Actividades Empresariales y Profesionales","VALOR": 612},
          { "CLAVE": 603, "NOMBRE": "Personas Morales con Fines no Lucrativos", "VALOR": 603},
          { "CLAVE": 610, "NOMBRE": "Residentes en el Extranjero sin Establecimiento Permanente en México", "VALOR": 610},
          { "CLAVE": 626, "NOMBRE": "Régimen Simplificado de Confianza", "VALOR": 626},
          { "CLAVE": 607, "NOMBRE": "Régimen de Enajenación o Adquisición de Bienes", "VALOR": 607},
          { "CLAVE": 625, "NOMBRE": "Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas","VALOR": 625 },
          { "CLAVE": 615, "NOMBRE": "Régimen de los ingresos por obtención de premios", "VALOR": 615},
          { "CLAVE": 616, "NOMBRE": "Sin obligaciones fiscales", "VALOR": 616},
          { "CLAVE": 620, "NOMBRE": "Sociedades Cooperativas de Producción que optan por diferir sus ingresos", "VALOR": 620},
          { "CLAVE": 605, "NOMBRE": "Sueldos y Salarios e Ingresos Asimilados a Salarios", "VALOR": 605 }
        ]
        this.getNeighborhoodByZipGnp()
        break;
      case 'hdi':
        this.url = environment.URL_HDI;
        this.formIssue = this.fb.group({
          empresa: ['gpi', Validators.required],

          ApellidoMaterno: [ this.data['quote']['apellido_materno'], Validators.required],
          ApellidoPaterno: [ this.data['quote']['apellido_paterno'], Validators.required],
          calle: ['', Validators.required],
          colonia: ['', Validators.required],
          cotizacion_id: [ this.data['selected_quote']['raw_response']['id'], Validators.required],
          numeroExterior: ['', Validators.required],
          numeroInterior: ['', Validators.required],
          numeroMotor: ['', Validators.required],
          numeroSerie: ['', Validators.required],
          placas: ['', Validators.required],
          PrimerNombre: [ this.data['quote']['nombre'], Validators.required],
          RFC: ['', Validators.required],
          telefonoCasa: [this.data['quote']['telefono'], Validators.required],
          EstadoCivil: ['S', Validators.required],
          regimenFiscal : [0, Validators.required]
        });
        this.regime = [
          {id: 0, name: 'Selecciona un régimen fiscal'},
          {id: 43676, name: 'Sueldos y salarios e ingresos asimilados a salarios'},
          {id: 43677, name: 'Arrendamiento'},
          {id: 43678, name: 'Régimen de enajenación o adquisición de bienes'},
          {id: 43679, name: 'Demás ingesos'},
          {id: 43680, name: 'Residentes en el extranjero sin estblecimiento permanente en México'},
          {id: 43681, name: 'Ingresos pot dividendos (socios y accionistas)'},
          {id: 43682, name: 'Personas físicas con actividades empresariales y profesionales'},
          {id: 43683, name: 'Ingresos por intereses'},
          {id: 43684, name: 'Régimen de los ingresos por obtencios de premios'},
          {id: 43685, name: 'Sin obligaciones fiscales'},
          {id: 43687, name: 'Incorporación fiscal'},
          {id: 43691, name: 'Régimen de las actividades empresariales con ingresos a tráves de plataformas tecnológicas'},
          {id: 43692, name: 'Régimne simplificado de confianza'}
        ]
        this.getNeighborhoodByZipHdi()
        break;
      case 'ps':
        this.url = environment.URL_PS;
        this.formIssue = this.fb.group({
          cotizacion_id: [ this.data['selected_quote']['raw_response']['id'], Validators.required],
          cte_nombre: [this.data['quote']['nombre'], Validators.required],
          cte_apepat: [this.data['quote']['apellido_paterno'], Validators.required],
          cte_apemat: [this.data['quote']['apellido_materno'], Validators.required],
          sexo: ['', Validators.required],
          fecha_nacimiento: [this.data['quote']['fecha_nacimiento'], Validators.required],
          email: [this.data['quote']['correo'], Validators.required],
          empresa: ['gpi', Validators.required],
          tel_num: [this.data['quote']['telefono'], Validators.required],
          dir_calle_num: ['', Validators.required],
          dir_col_id: ['0', Validators.required],
          rfc: ['', Validators.required],
          aut_no_serie: ['', Validators.required],
          aut_placas: ['', Validators.required],
        });
        this.getNeighborhoodByZipPrimero();
        break;
      case 'qualitas':
        this.url = environment.URL_QUALITAS;
        this.formIssue = this.fb.group({
          aut_no_serie: ['', Validators.required],
          aut_no_motor: ['', Validators.required],
          aut_placas: ['', Validators.required],
          cotizacion_id: [ this.data['selected_quote']['raw_response']['id'], Validators.required],
          cte_apemat: [this.data['quote']['apellido_materno'], Validators.required],
          cte_apepat: [this.data['quote']['apellido_paterno'], Validators.required],
          cte_nombre: [this.data['quote']['nombre'], Validators.required],
          dir_calle_num: ['', Validators.required],
          fecha_nacimiento: [this.data['quote']['fecha_nacimiento'], Validators.required],
          email: [this.data['quote']['correo'], Validators.required],
          empresa: ['gpi', Validators.required],
          rfc: ['', Validators.required],
          sexo: ['', Validators.required],
          tel_num: [this.data['quote']['telefono'], Validators.required]
        });
        break;
  
      default:
        break;
    }

    if(this.data['quote']['sexo'] && this.data['selected_quote']['aseguradora'] != 'hdi' && this.data['selected_quote']['aseguradora'] != 'ana' && this.data['selected_quote']['aseguradora'] != 'gnp'){
      this.getSex(this.data['quote']['sexo']);
    }

  }
  // ngOnInit(): void {
  //   throw new Error('Method not implemented.');
  // }


  redirect(){
    // this._router.navigate(['/resultados/' + this.data['id_cotizacion']]);
    this._router.navigate(['/comparador-auto']);
  }

  getSex(value:string){
    this.formIssue.controls['sexo'].setValue(value);
    if(value == 'F'){
      this.backgroundFemale = '#0474c4';
      this.backgroundMale = '#d4d4d4';
    }else{
      this.backgroundFemale = '#d4d4d4';
      this.backgroundMale = '#0474c4';
    }
  }

  getNeighborhoodByZipHdi(){
    
    this._services.getNeighborhoodbyZipHdi(this.url, this.data['quote']['cp']).then((response:any) => {
      this.neighborhoods = response['colonias'];
      this.neighborhoods.unshift({Colonia: 'Selecciona una colonia', id: '0'});
      this.formIssue.controls['colonia'].setValue(this.neighborhoods[0]['Colonia']);
    }).catch(error => {
      console.log(error);
    });
  };

  getNeighborhoodByZipAna(){
    
    this._services.getNeighborhoodbyZipAna(this.url, this.data['quote']['cp']).then((response:any) => {
      
      this.neighborhoods = response['transacciones']['colonias'];
      if(this.neighborhoods['#text']){
        let item = [];
        item.push(this.neighborhoods);
        this.neighborhoods = item.map((item:any) =>{
          item['text'] = item['#text'];
          item['id'] = item['@clave'];
          return item;
        })
      }else{
        this.neighborhoods = this.neighborhoods.map((item:any) =>{
          item['text'] = item['#text'];
          return item;
        })
      }  
      this.neighborhoods.unshift({text: 'Selecciona una colonia'});
      this.formIssue.controls['colonia'].setValue(this.neighborhoods[0]['text']);
      // console.log(this.neighborhoods);
      
    }).catch(error => {
      console.log(error);
    });
  };

  getNeighborhoodByZipGnp(){
    
    this._services.getNeighborhoodbyZipGnp(this.url, this.data['quote']['cp']).then((response:any) => {  

      if(response.CLAVE){
        this.neighborhoods.push(response);
      }else{
        this.neighborhoods = response;
      }      
      this.neighborhoods.unshift(
        {NOMBRE: 'Selecciona una colonia',CLAVE: '0'});
      this.formIssue.controls['colonia'].setValue(this.neighborhoods[0]['CLAVE']);
    }).catch(error => {
      console.log(error);
    });
    
  };

  getNeighborhoodByZipPrimero(){
    this._services.getNeighborhoodbyZipPrimero(this.url, this.data['quote']['cp']).then((response:any) => {
      this.neighborhoods = response;
      this.neighborhoods.unshift({nombre: 'Selecciona una colonia', clave: '0'});
      this.formIssue.controls['dir_col_id'].setValue(this.neighborhoods[0]['clave']);
    }).catch(error => {
      console.log(error);
    });
  };

  onSubmit(){        
    this.error = false;
    if(this.provider == 'ana'){
      if(this.formIssue['value']['serie'] == '' || this.formIssue['value']['serie'] == undefined){
        this.error = true;
        this.serieError = true;
        window.scrollTo(0, 0);
      }else{
        this.serieError = false;
      }
      if(this.formIssue['value']['motor'] == '' || this.formIssue['value']['motor'] == undefined){
        this.error = true;
        this.engineError = true;
        window.scrollTo(0, 0);
      }else{
        this.engineError = false;
      }
      if(this.formIssue['value']['rfc'] == '' || this.formIssue['value']['rfc'] == undefined){
        this.error = true;
        this.rfcError = true;
        window.scrollTo(0, 0);
      }else{
        this.rfcError = false;
      }
      if(this.formIssue['value']['curp'] == '' || this.formIssue['value']['curp'] == undefined){
        this.error = true;
        this.curpError = true;
        window.scrollTo(0, 0);
      }else{
        this.curpError = false;
      }
      if(this.formIssue['value']['calle'] == '' || this.formIssue['value']['calle'] == undefined){
        this.error = true;
        this.addressError = true;
        window.scrollTo(0, 0);
      }else{
        this.addressError = false;
      }
      if(this.formIssue['value']['numeroexterior'] == '' || this.formIssue['value']['numeroexterior'] == undefined){
        this.error = true;
        this.addressError = true;
        window.scrollTo(0, 0);
      }else{
        this.addressError = false;
      }
      if(this.formIssue['value']['colonia'] == '' || this.formIssue['value']['colonia'] == undefined || this.formIssue['value']['colonia'] == 'Selecciona una colonia'){
        this.error = true;
        this.neighborhoodError = true;
        window.scrollTo(0, 0);
      }else{
        this.neighborhoodError = false;
      }
      
    }else if(this.provider == 'hdi'){
      if(this.formIssue['value']['numeroSerie'] == '' || this.formIssue['value']['numeroSerie'] == undefined){
        this.error = true;
        this.serieError = true;
        window.scrollTo(0, 0);
      }else{
        this.serieError = false;
      }
      if(this.formIssue['value']['numeroMotor'] == '' || this.formIssue['value']['numeroMotor'] == undefined){
        this.error = true;
        this.engineError = true;
        window.scrollTo(0, 0);
      }else{
        this.engineError = false;
      }
      if(this.formIssue['value']['RFC'] == '' || this.formIssue['value']['RFC'] == undefined){
        this.error = true;
        this.rfcError = true;
        window.scrollTo(0, 0);
      }else{
        this.rfcError = false;
      }
      if(this.formIssue['value']['calle'] == '' || this.formIssue['value']['calle'] == undefined){
        this.error = true;
        this.addressError = true;
        window.scrollTo(0, 0);
      }else{
        this.addressError = false;
      }
      if(this.formIssue['value']['numeroExterior'] == '' || this.formIssue['value']['numeroExterior'] == undefined){
        this.error = true;
        this.addressError = true;
        window.scrollTo(0, 0);
      }else{
        this.addressError = false;
      }
      if(this.formIssue['value']['colonia'] == '' || this.formIssue['value']['colonia'] == undefined || this.formIssue['value']['colonia'] == 'Selecciona una colonia'){
        this.error = true;
        this.neighborhoodError = true;
        window.scrollTo(0, 0);
      }else{
        this.neighborhoodError = false;
      }
    }else if(this.provider == 'ps'){
      if(this.formIssue['value']['aut_no_serie'] == '' || this.formIssue['value']['aut_no_serie'] == undefined){
        this.error = true;
        this.serieError = true;
        window.scrollTo(0, 0);
      }else{
        this.serieError = false;
      }
      if(this.formIssue['value']['aut_placas'] == '' || this.formIssue['value']['aut_placas'] == undefined){
        this.error = true;
        this.platesError = true;
        window.scrollTo(0, 0);
      }else{
        this.platesError = false;
      }
      if(this.formIssue['value']['dir_calle_num'] == '' || this.formIssue['value']['dir_calle_num'] == undefined){
        this.error = true;
        this.addressError = true;
        window.scrollTo(0, 0);
      }else{
        this.addressError = false;
      }
      if(this.formIssue['value']['rfc'] == '' || this.formIssue['value']['rfc'] == undefined){
        this.error = true;
        this.rfcError = true;
        window.scrollTo(0, 0);
      }else{
        this.rfcError = false;
      }
    }else if(this.provider == 'gnp'){
      if(this.formIssue['value']['serie'] == '' || this.formIssue['value']['serie'] == undefined){
        this.error = true;
        this.serieError = true;
        window.scrollTo(0, 0);
      }else{
        this.serieError = false;
      }
      if(this.formIssue['value']['rfc'] == '' || this.formIssue['value']['rfc'] == undefined){
        this.error = true;
        this.rfcError = true;
        window.scrollTo(0, 0);
      }else{
        this.rfcError = false;
      }
      if(this.formIssue['value']['calle'] == '' || this.formIssue['value']['calle'] == undefined){
        this.error = true;
        this.addressError = true;
        window.scrollTo(0, 0);
      }else{
        this.addressError = false;
      }
      if(this.formIssue['value']['numero_exterior'] == '' || this.formIssue['value']['numero_exterior'] == undefined){
        this.error = true;
        this.addressError = true;
        window.scrollTo(0, 0);
      }else{
        this.addressError = false;
      }  
      if(this.formIssue['value']['regimen_fiscal'] == '' || this.formIssue['value']['regimen_fiscal'] == undefined){
        this.error = true;
        this.regimeError = true;
        window.scrollTo(0, 0);
      }else{
        this.regimeError = false;
      }  
      if(this.formIssue['value']['razon_social'] == '' || this.formIssue['value']['razon_social'] == undefined){
        this.error = true;
        this.razonError = true;
        window.scrollTo(0, 0);
      }else{
        this.razonError = false;
      }
      if(this.formIssue['value']['placas'] == '' || this.formIssue['value']['placas'] == undefined){
        this.error = true;
        this.platesError = true;
        window.scrollTo(0, 0);
      }else{
        this.platesError = false;
      } 
    }

    if(this.error){
      return;
    }

    this.loader = true;
    if(this.provider == 'hdi' || this.provider == 'ps' || this.provider == 'ana' || this.provider == 'gnp'){
      this._services.getIssue(this.url, this.formIssue['value']).then((response:any) => {
        if(response['errMsg']){
          if(response['errMsg'].indexOf("RFC") == 0 ){
            Swal.fire('Error','RFC inválido', 'error');
            this.loader = false;
            return;
          }else if(response['errMsg'].indexOf("Numero de serie") == 0 ){
            Swal.fire('Error','Número de serie inválido', 'error');
            this.loader = false;
            return;
          }else if(response['errMsg'].indexOf("telefono") == 0 ){
            Swal.fire('Error','Número de teléfono inválido', 'error');
            this.loader = false;
            return;
          }else{
            Swal.fire('Error',response['errMsg'],'error');
            this.loader = false;
          }
        }else if(response['error']){
          Swal.fire('Error',response['error'], 'error');
          this.loader = false;
        }else if(response['ERROR']){
          Swal.fire('ERROR',response['ERROR']['DESCRIPCION'], 'error');
          this.loader  = false;
        }else{
          this._settings['settings']['issue'] = response;
          this._settings.saveSettings();
          let payload:any = {};
          this.data['selected_quote']['impresion'] = response.poliza_pdf ? response.poliza_pdf : true;
          payload['status'] = '2';
          payload[this.data['selected_quote']['aseguradora']] = JSON.stringify(this.data['selected_quote']);
          console.log(this.data);

          this._services.saveQuote(this.data['id_cotizacion'], payload).then((respo:any) => {
          }).catch(error => {
            console.error(error);
            Swal.fire(error['error'], 'Error');
          }); 
          this._router.navigate(['/impresion-auto']);
        }
      }).catch(error => {
        console.error(error);
        Swal.fire('Error',error['error'], 'error');
      });
    }else{
      let payload:any = {};
      this.data['selected_quote']['emision'] = {
        rfc: this.formIssue['value']['rfc'], 
        calle: this.formIssue['value']['dir_calle_num'], 
        serie: this.formIssue['value']['aut_no_serie'], 
        motor: this.formIssue['value']['aut_no_motor'], 
        placas: this.formIssue['value']['aut_placas']
      };
      payload['status'] = '1';
      payload[this.data['selected_quote']['aseguradora']] = JSON.stringify(this.data['selected_quote']);      
      this._services.saveQuote(this.data['id_cotizacion'], payload).then((response:any) => {
        if(response['id']){
          let obj = {
            origen: 'avantthya',
            asunto: 'Contacto avantthya',
            id_cotizacion: this.data['id_cotizacion']
          }
          this._services.sendEmailContact(obj).then((response:any) => {

          }).catch(error => {
            console.log(error);
          });
          this.loader = false;
          Swal.fire('Listo', 'Tus datos han sido guardados. En breve un asesor se pondrá en contacto contigo.', 'success');
          this._router.navigate(['/cotizador']);
        }
      }).catch(error => {
        console.log(error);
      });
    }
  }

}

// emision pasada
// toEmit(){
//   let dia_asegurado = this.birthdateInsured.substr(0, 2);
//   let mes_asegurado = this.birthdateInsured.substr(3, 2);
//   let anio_asegurado = this.birthdateInsured.substr(6, 4);

//   dia_asegurado = dia_asegurado.length == 1 ? '0' + dia_asegurado : dia_asegurado;
//   mes_asegurado = mes_asegurado.length == 1 ? '0' + mes_asegurado : mes_asegurado;

//   this.emision['birthdate'] = new Date(anio_asegurado + '-' + mes_asegurado + '-' + dia_asegurado).toISOString();
//   this.emision['fechaNacimiento_asegurado'] = anio_asegurado + mes_asegurado + dia_asegurado;
  
//   let dia_contratante = this.birthdateContractor.substr(8, 2);
//   let mes_contratante = this.birthdateContractor.substr(5, 2);
//   let anio_contratante = this.birthdateContractor.substr(0, 4);

//   dia_contratante = dia_contratante.length == 1 ? '0' + dia_contratante : dia_contratante;
//   mes_contratante = mes_contratante.length == 1 ? '0' + mes_contratante : mes_contratante;

//   this.emision['fechaNacimiento_contratante'] = anio_contratante + mes_contratante + dia_contratante;

//   this.emision['car_sex'] = this.emision['sexo_asegurado'] == 'Femenino' ? 'F' : 'M';

//   this.emision['address_street'] = this.emision['ubicacion_direccion'];
//   this.emision['address_number'] = this.emision['numero_direccion'];
//   this.emision['address_int_number'] = '-';
//   this.emision['city'] = this.cotizacion['ddMun'];
//   this.emision['state'] = this.emision['estado_direccion'];
//   this.emision['first_name'] = this.emision['nombre_asegurado'];
//   this.emision['last_name'] = this.emision['apaterno_asegurado'];
//   this.emision['second_last_name'] = this.emision['amaterno_asegurado'];
//   this.emision['phone_number'] = this.emision['numero_1_contratante'];
//   this.emision['rfc'] = this.emision['rfc_asegurado'];
//   this.emision['civil_status'] = this.emision['edoCivil_asegurado'] == 'Soltero/a' ? 1 : 2;
//   this.emision['lada'] = '55';
//   this.emision['tipoPersona_contratante'] = 'F';
//   this.emision['tipoCarga'] = '';
//   this.emision['quote_id'] = this.cotizacion['quote_id'];
//   this.emision['urlname'] = this.cotizacion['urlname'];

//   this.emision['zip_code'] = this.cotizacion['zip_code'];
//   this.emision['address_col'] = this.emision['colonia_direccion'];

//   this._api.toEmit(this._settings['settings']['cotizacion_selecionada']['service']['id'], this.emision).then(response => {
//     if(response['emision']){
//       if(response['emision']['poliza']){
//         this.policyNumber = response['emision']['poliza']['numero'];
//         this.showIssue = true;
//       }else if(response['emision']['idPoliza']){
//         this.policyNumber = response['emision']['numero'];
//         this.showIssue = true;
//       }else{
//         this.showAlertError = true;
//         this.messageError = response['emision'];
//         return;
//       }
//     }else{
//       if(response['error'] == '[Layout validation error , bean  [Vehiculo], invalid property value [numeroSerie]]'){
//         this.showAlertError = true;
//         this.messageError = 'Número de serie incorrecto';
//         return;
//       }else if(response['error'] == '[Layout validation error, bean  [Contratante]Email del Contratante inválido[email]]'){
//         this.showAlertError = true;
//         this.messageError = 'Correo incorrecto';
//         return;
//       }else{
//         this.showAlertError = true;
//         this.messageError = response['error'];
//         return;
//       }
//     }

//     let emision_result = response['data'];
//     window.localStorage.emision_result = JSON.stringify(emision_result);

//   }).catch(error => {
//     console.log(error);
//   });
// }
