<div class="custom" >
  <embed
  src="https://miurabox.s3.amazonaws.com/manuales/GPI_Seguro_de_Bicicleta.pdf"
  type="application/pdf"
  frameBorder="0"
  scrolling="auto"
  height="100%"
  width="100%"
  />
</div>
                  