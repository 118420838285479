<div class="container-main">
  <div class="container-green">
    <div class="container">
      <div class="row">
        <div class="col-12 form-group">
          <p class="text-white">¡Cumple todos tus sueños!</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-grey">
    <div class="container">
      <div class="row justify-content-around">
        <div class="col-12 form-group">
          <p class="title-green">CRÉDITOS</p>
        </div>
        <div class="col-12 col-sm-6 form-group">
          <p class="text-green-small">¡Hazlo sin miedo!</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-white">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-6 col-md-4 form-group">
          <img class="image-credit" src="../../../assets//images/credit/img_creditos.png" alt="">
        </div>
        <div class="col-12 col-sm-8 form-group">
          <p class="title-grey">Gracias a nuestras alianzas con proveedores, bancos e instituciones financieras</p>
          <p class="text-grey">podemos brindarte soluciones alcanzables para lograr tus sueños.</p>
          <p class="text-grey">20 años de experiencia ofreciendo servicios financieros nos respaldan.</p>
          <p class="text-grey">Sin costo te ayudaremos a elegir, tramitar y obtener productos financieros según tus necesidades, con un respaldo total y con mejores tiempos de respuesta.</p>
          <p class="text-green-credit">Ofrecemos un portafolio robusto de servicios financieros</p>
          <p class="text-grey">con el que podemos apoyar tanto a familias como a la industria, mediante créditos hipotecarios o financiamiento empresarial, dependiendo de las necesidades de cada caso.</p>
        </div>
      </div>
      <div class="row justify-content-around">
        <div class="col-12 form-group">
          <p class="text-green">Podemos ayudarte con:</p>
        </div>
        <div class="col-12 col-sm-4 form-group">
          <div class="card">
            <div class="card-header">
              <img class="image-card" src="../../../assets/images/credit/ico_creditos_hipotecario.svg" alt="">
              <p class="text-green-card">Crédito Hipotecario</p>
            </div>
            <div class="card-body">
              <p><img src="../../../assets/images/credit/arrow.png" alt=""> Adquisición Tradicional</p>
              <p><img src="../../../assets/images/credit/arrow.png" alt=""> Mejora de Hipotecas</p>
              <p><img src="../../../assets/images/credit/arrow.png" alt=""> Compra de Terreno</p>
              <p><img src="../../../assets/images/credit/arrow.png" alt=""> Construcción</p>
              <p><img src="../../../assets/images/credit/arrow.png" alt=""> Terreno + Construcción</p>
              <p><img src="../../../assets/images/credit/arrow.png" alt=""> Cofinavit</p>
              <p><img src="../../../assets/images/credit/arrow.png" alt=""> FOVISSSTE para todos</p>
              <p><img src="../../../assets/images/credit/arrow.png" alt=""> Liquidez</p>
              <button class="button-green" (click)="goToLink('https://wa.link/8uhq1h')">¡Solicita tu crédito!</button>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 form-group">
          <div class="card">
            <div class="card-header">
              <img class="image-card" src="../../../assets/images/credit/ico_creditos_pyme_empresarial.svg" alt="">
              <p class="text-green-card">Crédito PyME Empresarial</p>
            </div>
            <div class="card-body">
              <div class="box-card">
                <p><img src="../../../assets/images/credit/arrow.png" alt=""> Crédito Simple</p>
                <p><img src="../../../assets/images/credit/arrow.png" alt=""> Credito Revolvente</p>
                <p><img src="../../../assets/images/credit/arrow.png" alt=""> Arrendamiento</p>
                <p><img src="../../../assets/images/credit/arrow.png" alt=""> Factoraje</p>
                <p><img src="../../../assets/images/credit/arrow.png" alt=""> Crédito Puente para Vivienda</p>
              </div>
                <button class="button-green" (click)="goToLink('https://wa.link/8uhq1h')">¡Solicita tu crédito!</button>
            </div>
          </div>
        </div>
        <div class="col-12 form-group">
          <p class="subtitle-green">Asesoría y gestión de créditos SIN COSTO</p>
          <p class="text-help">La asesoría y gestión de tu crédito con nosotros no tiene costo y te garantizamos</p>
          <p class="text-help">que en menos de 24 horas tendremos toda la información que necesitas:</p>
          <p class="text-help">envío de simuladores y lista de documentos para la formalización del crédito.</p>
          <br>
          <br>
          <br>
        </div>
      </div>
    </div>
  </div>
</div>