<div class="container-main">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#"><img class="image-logo-menu" src="../../../assets/images/header/logo_gpi_menu.svg" alt=""></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">        
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" [ngStyle]="{'color':textHome}" (click)="goToPage('inicio')">Inicio</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngStyle]="{'color':textPolicy}" (click)="goToPage('polizas')">Seguros</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngStyle]="{'color':textSurety}" (click)="goToPage('fianzas')">Fianzas</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngStyle]="{'color':textCredit}" (click)="goToPage('capital')">Créditos</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngStyle]="{'color':textBlog}" (click)="goToPage('blog')">Blog</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngStyle]="{'color':textContact}" (click)="goToPage('contacto')">Contacto</a>
          </li>
        </ul>
        <form class="d-flex">
          <img class="image-logo" src="../../../assets/images/header/logo_gpi_menu.svg" alt="">
        </form>
      </div>
    </div>
  </nav>
</div>

<div class="container-contact" *ngIf="showContact">
  <div class="col-12 form-group">
    <p class="text-contact">¡Solicita tu cotización!</p>
  </div>
  <!-- <div class="col-12 form-group">
    <button class="button-chat">Chat</button>
  </div> -->
  <div class="col-12 form-group">
    <button *ngIf="showCredit" class="button-messenger" (click)="goToLink('https://m.me/GPI.SegurosMX')">Messenger</button>
    <button *ngIf="!showCredit" class="button-messenger" (click)="goToLink('https://m.me/100063753600518')">Messenger</button>
  </div>
  <div class="col-12 form-group">
    <button *ngIf="showCredit" class="button-whatsapp" (click)="goToLink('https://wa.link/8uhq1h')">WhatsApp</button>
    <button *ngIf="!showCredit" class="button-whatsapp" (click)="goToLink('https://wa.link/8uhq1h')">WhatsApp</button>
  </div>
</div>