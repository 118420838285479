import { Component, OnInit } from '@angular/core';
import { PromiseComponent } from '../promise/promise.component';
import Swal from 'sweetalert2';
// import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-administrator',
  templateUrl: './administrator.component.html',
  styleUrls: ['./administrator.component.css']
})
export class AdministratorComponent implements OnInit {

  showInformation = false;
  user = '';
  password = '';

  showAdd = false;
  showItem = false;
  single_file:any;
  filter = {};
  categories = [
    {value: 0 ,name: 'Selecciona una categoría'},
    {value: 1 ,name: 'Grupo gpi'}
  ];
  actions = [
    {value: 0 ,name: 'Selecciona una acción'},
    {value: 1 ,name: 'Agregar'},
    {value: 2 ,name: 'Editar'},
    {value: 3 ,name: 'Eliminar'}
  ];
  items:any = [];
  title = {};
  itemRes:any;
  subtitles:any = [];
  comments:any;
  card = {};
  file:any;
  imagen:any;
  showFile = false;
  imagePath;
  imgURL: any;
  message: string;

  imageChangedEvent: any = '';
  croppedImage: any = '';

  constructor(
    public _api: PromiseComponent
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.filter['category'] = this.categories[0].name;
    this.filter['action'] = this.actions[0].value;
  }

  goToAdmin(){
    this._api.login(this.user, this.password).then(response=>{
      if(response['token_jwt']){
        this.showInformation = true;
      }
      if(response['error']){
      }
    }).catch(error=>{
      console.log(error);
      Swal.fire('Error', error['error']['error'], 'error');
    })
  };

  logout(){
    this.showInformation = false;
  };

  getActions(){
    this.filter['action'] = this.actions[0].value;
    this.showItem = false;
  };

  getItems(){
    if(this.filter['action'] == 1){
      this.showAdd = true;
      this.showItem = false;
      this.items = [];
      this.items.unshift({title: 'Selecciona un artículo', id: 0});
      this.filter['item'] = this.items[0].id;
      this.addSubtitle();
    }else{
      this.showAdd = false;
      this.showItem = true;

      this._api.get_cards().then(response=>{
        if(response){
          this.items = [];
          let data = [];
          for(let i = 0; i < response['length']; i++){
            data.push(response[i]);
          }
          this.items = data;
          this.items.unshift({title: 'Selecciona un artículo', id: 0});
          this.filter['item'] = this.items[0].id;
        }
      }).catch(error=>{
        console.log(error);
      })
    }
  };

  addSubtitle(){
    let sub = {
      title:'',
      text: '',
      priority: 1
    }
console.log(this.subtitles)
    this.subtitles.push(sub);
  }

  deleteSubtitle(item, index){
    console.log(index)
    console.log(item)
    if(item['id']){
      Swal.fire({
        title: 'Eliminar Sutítulo',
        text: "¿Estas seguro?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          this._api.delete_subcards(item['id']).then(response=>{
            Swal.fire('¡Listo!', 'Subtítulo eliminado.', 'success');
            this.subtitles.splice(index, 1);
          }).catch(error=>{
            console.log(error);
          })
        } else {
          Swal.fire('Error', 'Ocurrió un error', 'error')
        }
      })
    }else{
      this.subtitles.splice(index, 1);
    }
  }

  save(){
    this.title['priority'] = 0;
    this.title['category'] = this.filter['category'];

    this._api.save_cards(this.title).then(response=>{
      for(let item of this.subtitles){
        this._api.save_subcards(response['id'], item).then(responses=>{

        }).catch(error=>{
          console.log(error);
        })
      }
      Swal.fire('¡Listo!', 'Artículo guardado.', 'success');
    }).catch(error=>{
      console.log(error);
    })
  }

  imageLoaded(){}

  cropperReady(){}

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.showFile = true;
  }

  b64toBlob(b64Data, contentType, sliceSize = 512) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }
    
  // imageCropped(event: ImageCroppedEvent) {
  //   this.croppedImage = event.base64;
  // }

  onFileChanged(event) {
    this.single_file = event.target.files[0]
  }

  onUpload() {
    const uploadData = new FormData();
    console.log(this.single_file);
    uploadData.append('file', this.single_file, this.single_file.name);
    this._api.save_image(3,uploadData).then(responses=>{
      this.showFile = false;
    }).catch(error=>{
      console.log(error);
    })
  }

  change_imagen(event){
    var ImageURL = this.croppedImage;
    // Split the base64 string in data and contentType
    var block = ImageURL.split(";");
    // Get the content type of the image
    var contentType = block[0].split(":")[1];
    // get the real base64 content of the file
    var realData = block[1].split(",")[1];
    console.log("145",this.imageChangedEvent)
    // Convert it to a blob to upload
    console.log(this.imageChangedEvent.target.name)
    var blob = this.b64toBlob(realData, contentType);
    var file = new File([blob],this.imageChangedEvent.target.name, {
      type: contentType
    });

    // Create a FormData and append the file with "image" as parameter name
    var formDataToUpload = new FormData();
    formDataToUpload.append("avatar", file);
    let payload = {  
      "avatar": this.croppedImage
    }
    
    // this._api.save_image( this.user.userinfo.url.replace('user-info','user-avatar-info') , payload).then(response=>{
    //   this.user.userinfo['avatar'] = response['avatar'];
    //   this.showImageProfile = true;
    //   this._toaster.success('Imagen guardada correctamente');
    // });

    console.log("166",file)

    this._api.save_image(3, file).then(responses=>{
      this.showFile = false;
    }).catch(error=>{
      console.log(error);
    })
  }

  preview(files) {
    if (files.length === 0)
      return;
 
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
 
    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]); 
    reader.onload = (_event) => { 
      this.imgURL = reader.result; 
    }
  }

  getInformation(param){
    if(this.items){
      for(let item of this.items){
        if(item.id == param){
          this.title = item;
        }
      }
    }
  }
  
  getAction(){
    if(this.filter['action'] == 2){
      this.showAdd = true;
      this._api.get_subcards(this.title['id']).then(response=>{
        this.itemRes = response
        if(response){
          for(let item of this.itemRes){
            this.subtitles.push(item);
          }
        }
      }).catch(error=>{
        console.log(error);
      })
    }else if(this.filter['action'] == 3){
      this._api.get_comments(this.title['id']).then(response=>{
        if(response){
          this.comments = response;
        }
      }).catch(error=>{
        console.log(error);
      })
      this._api.get_subcards(this.title['id']).then(response=>{
        if(response){
          this.subtitles = [];
          this.subtitles = response;
        }
      }).catch(error=>{
        console.log(error);
      })
      Swal.fire({
        title: 'Eliminar Artículo',
        text: "¿Estas seguro?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          if(this.comments['length']){
            for (let i = 0; i < this.comments['length']; i++) {
              this._api.delete_comments(this.comments[i]['id']).then(responses=>{
                
              }).catch(error=>{
                console.log(error);
              })
              if((i + 1) == this.comments['length']){
                if(this.subtitles['length']){
                  for (let j = 0; j < this.subtitles['length']; j++) {
                    this._api.delete_subcards(this.subtitles[j]['id']).then(responses=>{
                      
                    }).catch(error=>{
                      console.log(error);
                    })
                    if((j + 1) == this.subtitles['length']){
                      this._api.delete_cards(this.title['id']).then(responses=>{
                        this._api.get_cards().then(response=>{
                          if(response){
                            this.items = [];
                            let dataA = [];
                            let dataP = [];
                            for(let i = 0; i < response['length']; i++){
                              if(response[i]['category'] == 'Agentes de Seguros'){
                                dataA.push(response[i]);
                              }
                              if(response[i]['category'] == 'Pólizas de Seguros'){
                                dataP.push(response[i]);
                              }
                            }
                            this.items = dataA;
                            this.items.unshift({title: 'Selecciona un artículo', id: 0});
                            this.filter['item'] = this.items[0].id;
                          }
                          Swal.fire('¡Listo!', 'El artículo ha sido eliminado.', 'success');
                        }).catch(error=>{
                          console.log(error);
                        })
                      }).catch(error=>{
                        console.log(error);
                      })
                    }
                  }
                }else{
                  this._api.delete_cards(this.title['id']).then(responses=>{
                    this._api.get_cards().then(response=>{
                      if(response){
                        this.items = [];
                        let data = [];
                        for(let i = 0; i < response['length']; i++){
                          data.push(response[i]);
                        }
                        this.items = data;
                        this.items.unshift({title: 'Selecciona un artículo', id: 0});
                        this.filter['item'] = this.items[0].id;
                      }
                      Swal.fire('¡Listo!', 'El artículo ha sido eliminado.', 'success');
                    }).catch(error=>{
                      console.log(error);
                    })
                  }).catch(error=>{
                    console.log(error);
                  })
                }
              }
            }
          }else{
            if(this.subtitles['length']){
              for (let j = 0; j < this.subtitles['length']; j++) {
                this._api.delete_subcards(this.subtitles[j]['id']).then(responses=>{
                  
                }).catch(error=>{
                  console.log(error);
                })
                if((j + 1) == this.subtitles['length']){
                  this._api.delete_cards(this.title['id']).then(responses=>{
                    this._api.get_cards().then(response=>{
                      if(response){
                        this.items = [];
                        let dataA = [];
                        let dataP = [];
                        for(let i = 0; i < response['length']; i++){
                          if(response[i]['category'] == 'Agentes de Seguros'){
                            dataA.push(response[i]);
                          }
                          if(response[i]['category'] == 'Pólizas de Seguros'){
                            dataP.push(response[i]);
                          }
                        }
                        this.items = dataA;
                        this.items.unshift({title: 'Selecciona un artículo', id: 0});
                        this.filter['item'] = this.items[0].id;
                      }
                      Swal.fire('¡Listo!', 'El artículo ha sido eliminado.', 'success');
                    }).catch(error=>{
                      console.log(error);
                    })
                  }).catch(error=>{
                    console.log(error);
                  })
                }
              }
            }else{
              this._api.delete_cards(this.title['id']).then(responses=>{
                this._api.get_cards().then(response=>{
                  if(response){
                    this.items = [];
                    let dataA = [];
                    let dataP = [];
                    for(let i = 0; i < response['length']; i++){
                      if(response[i]['category'] == 'Agentes de Seguros'){
                        dataA.push(response[i]);
                      }
                      if(response[i]['category'] == 'Pólizas de Seguros'){
                        dataP.push(response[i]);
                      }
                    }
                    this.items = dataA;
                    this.items.unshift({title: 'Selecciona un artículo', id: 0});
                    this.filter['item'] = this.items[0].id;
                  }
                  Swal.fire('¡Listo!', 'El artículo ha sido eliminado.', 'success');
                }).catch(error=>{
                  console.log(error);
                })
              }).catch(error=>{
                console.log(error);
              })
            }
          }
        } else {
          Swal.fire('Cancelado', 'No se eliminó el artículo.', 'error')
        }
      })
    }
  }

  update(){
    this._api.edit_cards(this.title['id'], this.title).then(responses=>{
      for(let item of this.subtitles){
        if(item['id']){
          this._api.edit_subcards(item['id'], item).then(responses=>{
    
          }).catch(error=>{
            console.log(error);
          })
        }else {
          this._api.save_subcards(this.title['id'], item).then(responses=>{

          }).catch(error=>{
            console.log(error);
          })
        }
      }
      Swal.fire('¡Listo!', 'El artículo ha sido actualizado.', 'success');
    }).catch(error=>{
      console.log(error);
    })
  }

}
