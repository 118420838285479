import { Component, OnInit } from '@angular/core';
import { PromiseComponent } from '../promise/promise.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.css']
})
export class DocsComponent implements OnInit {
  docs = {
  }

  showAlertError;
  showAlertEmail;
  showAlertSuccess;

  constructor(
    public _api: PromiseComponent
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    // const button = document.getElementById('ver')
    // button?.dispatchEvent(new MouseEvent('click'));
  }

  goToLink(url: string){
    fetch(url).then(function(t) {
      return t.blob().then((b)=>{
          var a = document.createElement("a");
          a.href = URL.createObjectURL(b);
          a.setAttribute("download", "GPI_Seguro_de_Bicicleta.pdf");
          a.click();
      }
      );
    });
  }
  cancel(){
    this.showAlertError = false;
    this.showAlertEmail = false;
    this.showAlertSuccess = false;
  }

}
