import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from 'src/app/services/services.index';

@Component({
  selector: 'app-promise',
  templateUrl: './promise.component.html',
  styles: []
})
export class PromiseComponent implements OnInit {

  apiUrl:string;
  casUrl:string;
  mcUrl:string;
  fastUrl:string;
  urlname:string;
  token:string;
  org:string;
  credential:any = {
    user: '',
    password: '',
  }
  constructor(
    private http: HttpClient,
    public _settings: SettingsService
  ) {
    this.apiUrl = 'https://api.miurabox.com/';
    this.casUrl = 'https://users-api.miurabox.com/';
    this.mcUrl = 'https://gpimc.multicotizador.com/';
    this.fastUrl = 'https://heysaam-api-test.miurabox.com/';
    this.urlname = 'gpimc';
    this.org = 'gpi';
    // this.apiUrl = 'http://127.0.0.1:8000/';
    // this.casUrl = 'http://127.0.0.1:9000/';
    // this.mcUrl = 'http://127.0.0.1:8001/';
    // this.urlname = 'local';
    // this.org = 'gpi';
    this.credential['user'] = 'superuser_gpi';
    this.credential['password'] = 'gpi2023s';
  }

  ngOnInit() {
  }

  login(user, pass){
    return new Promise((resolve,reject) => {
      let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json'
      }
      let data = 'username=' + encodeURIComponent(user) + '&password=' + encodeURIComponent(pass) + '&org=' + encodeURIComponent(this.org)
      this.http.post(this.casUrl + 'api-token-auth-saam/', data = data, {headers : headers})
      .subscribe(data => {
        this.token = data['token_jwt'];
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  };

  loginMc(){
    return new Promise((resolve,reject) => {
      let headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json'
      }
      let data = 'username=' + encodeURIComponent(this.credential['user']) + '&password=' + encodeURIComponent(this.credential['password']) + '&urlname=' + this.urlname + '&org=' + this.org;
      this.http.post(this.casUrl + 'us-login-multicotizador', data=data, {headers : headers})
      .subscribe(data => {
        this._settings['settings']['urlname'] = this.urlname;
        this._settings['settings']['org'] = this.org;
        this._settings.saveSettings();
        resolve(data);
      }, error => {
        reject(error)
      });
    });
  };

  get_cards(){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.get(this.fastUrl+'entries/Grupo%20gpi', { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }
  
  get_subcards(id){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.get(this.fastUrl+'subtitles/'+id+'?skip=0&limit=100', { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }
  
  get_comments(id){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.get(this.fastUrl+'comments/'+id+'?skip=0&limit=100', { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }
  
  save_cards(item){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.post(this.fastUrl+'entries/', item, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }
  
  save_subcards(id, item){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.post(this.fastUrl+'entries/'+id+'/subtitles/', item, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  save_comments(id, item){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.post(this.fastUrl+'entries/'+id+'/comment/', item, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }
  
  save_image(id, file){
    return new Promise((resolve,reject) => {
      let headers = new HttpHeaders();
      // headers.append('Content-Type', 'multipart/form-data');
      headers.append('Authorization', 'Bearer '+this.token);
      this.http.post(this.fastUrl+'entries/'+id+'/files/', file, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  delete_cards(id){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.delete(this.fastUrl+'entries/'+id, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  delete_subcards(id){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.delete(this.fastUrl+'subtitles/'+id, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  delete_comments(id){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.delete(this.fastUrl+'comments/'+id, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }
  
  edit_cards(id, data){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.patch(this.fastUrl+'entries/'+id, data, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }
  
  edit_subcards(id, data){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+this.token}
      this.http.patch(this.fastUrl+'subtitle/'+id, data, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  }

  send_contact(contact){
    return new Promise((resolve,reject) => {
      this.http.post(this.apiUrl+'send-emai-contact-gpi', contact)
      .subscribe(data => {
        resolve(data);
      }, err => {
        reject(err)
      });
    });
  }

  

  getBrands(type:string){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json'}
      this.http.get(this.mcUrl+'get-car-brand/'+type, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };

  getYears(type:string, brand:string){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json'}
      this.http.get(this.mcUrl+'get-car-year/'+type+'/'+brand, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };

  getModels(type:string, brand:string, year:string){
    return new Promise((resolve,reject) => {
      let headers = {'Content-Type': 'application/json'}
      this.http.get(this.mcUrl+'get-car-model/'+type+'/'+brand+'/'+year, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };
  
  getSuburbs(code:string){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+token}
      this.http.get(this.mcUrl+'get-cols-by-cp/'+code, { headers: headers })
      .subscribe(data => {
        resolve(data);
      },err => {
        reject(err)
      })
    });
  };

  getQuotation(guotation){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+token}
      this.http.post(this.mcUrl+'get-services', guotation, {headers: headers })
      .subscribe(data=>{
        resolve(data);
      },err=>{reject(err)})
    });
  };
  sendEmailAuto(guotation){
    console.log('------quot',guotation)
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+token}
      this.http.post(this.apiUrl+'send-email-cotizacion-request/', guotation, {headers: headers })
      .subscribe(data=>{
        resolve(data);
      },err=>{reject(err)})
    });
  };
  getComparison(id, guotation){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+token}
      this.http.post(this.mcUrl+'run-service/'+id, guotation, {headers: headers })
      .subscribe(data=>{
        resolve(data);
      },err=>{reject(err)})
    });
  };

  downloadPdf(pdf){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+token}
      this.http.post(this.mcUrl+'pdf-gpi/', pdf, {headers: headers })
      .subscribe(data=>{
        resolve(data);
      },err=>{reject(err)})
    });
  };

  sendEmail(email){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+token}
      this.http.post(this.mcUrl+'send-email', email, {headers: headers })
      .subscribe(data=>{
        resolve(data);
      },err=>{reject(err)})
    });
  };

  toEmit(id, emision){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {'Content-Type': 'application/json', 'Authorization': 'Bearer '+token}
      this.http.post(this.mcUrl+'run-emision-service/'+id, emision, {headers: headers })
      .subscribe(data=>{
        resolve(data);
      },err=>{reject(err)})
    });
  };
  
  print(id, data){
    return new Promise((resolve,reject) => {
      let token =  this._settings['settings']['token'];
      let headers = {
        'Content-Type': 'application/json', 'responseType'  : 'arraybuffer', 'Authorization': 'Bearer '+token,
      }
      this.http.post(this.mcUrl+'run-impresion-service/'+id, data, {headers: headers })
      .subscribe(data=>{
        resolve(data);
      },err=>{reject(err)})
    });
  };

}