import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { SharedModule } from '../shared/shared.module';
import { PAGES_ROUTES } from './pages.routes';

import { PromiseComponent } from './promise/promise.component';

import { PagesComponent } from './pages.component';
import { HomeComponent } from './home/home.component';
import { PolicyComponent } from './policy/policy.component';
import { SuretyComponent } from './surety/surety.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';
import { CarComponent } from './car/car.component';
import { CarComparisonComponent } from './car-comparison/car-comparison.component';
import { CarIssueComponent } from './car-issue/car-issue.component';
import { HouseComponent } from './house/house.component';
import { MedicalComponent } from './medical/medical.component';
import { AdministratorComponent } from './administrator/administrator.component';
import { DocsComponent } from './docs/docs.component';
import { CreditsComponent } from './credits/credits.component';
import { MigpiComponent } from './migpi/migpi.component';
import { CarPrintComponent } from './car-print/car-print.component';

@NgModule({
  declarations: [
    PagesComponent,
    HomeComponent,
    PolicyComponent,
    SuretyComponent,
    BlogComponent,
    ContactComponent,
    AboutComponent,
    CarComponent,
    HouseComponent,
    MedicalComponent,
    CarComparisonComponent,
    CarIssueComponent,
    CarPrintComponent,
    AdministratorComponent,
    DocsComponent,
    CreditsComponent,
    MigpiComponent,
  ],
  exports: [],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    SharedModule,
    PAGES_ROUTES,
    ReactiveFormsModule,
  ],
  providers: [
    PromiseComponent
  ]
})
export class PagesModule { }