<div class="container-main">
  <div class="container-purple">
    <div class="container">
      <div class="row">
        <div class="col-12 form-group">
          <p class="text-white">¡Tenemos las tarifas más competitivas del mercado!</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-grey">
    <div class="container">
      <div class="row">
        <div class="col-12 form-group">
          <p class="text-purple">¡Hazlo sin miedo!</p>
          <p class="text-purple-small">Precios justos <span class="point-orange">·</span> Atención de primera <span class="point-orange">·</span> Respaldo total</p>
        </div>
        <div class="col-12 col-sm-4 form-group">
          <div class="container-image">
            <img class="image-surety" src="../../../assets/images/surety/img_seccion_fianzas.png" alt="">
          </div>
        </div>
        <div class="col-12 col-sm-8 form-group">
          <p class="text-grey">¡Te damos el respaldo que tu negocio necesita!</p>
          <p class="text-grey-small">Más de 50 años en total y un equipo especializado en Fianzas, nos avalan como tu mejor opción para garantizar y afianzar tus proyectos.</p>
          <p class="text-purple-surety">En caso de licitación, requisito, convenios, proveedores o concursos...</p>
          <p class="text-purple-small-surety">¡Tenemos la fianza que necesitas con las tarifas más competitivas del mercado!</p>
          <p class="text-orange">Contáctanos para cotizar la fianza que necesitas</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-white">
    <div class="container">
      <div class="row">
        <div class="col-12 form-group">
          <p class="title-purple">Fianzas administrativas y más</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-4 form-group">
          <div class="cards">
            <img class="image-card" src="../../../assets/images/surety/ico_fianza_obra.svg" alt="">
            <p class="text-card-orange">Fianzas para Obra</p>
            <ul>
              <li>
                <p class="text-list">· Licitación</p>
              </li>
              <li>
                <p class="text-list">· Anticipo</p>
              </li>
              <li>
                <p class="text-list">· Cumplimiento</p>
              </li>
              <li>
                <p class="text-list">· Buena Calidad</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-sm-4 form-group">
          <div class="cards">
            <img class="image-card" src="../../../assets/images/surety/ico_fianza_proveeduria.svg" alt="">
            <p class="text-card-orange">Fianzas de Proveeduría</p>
            <ul>
              <li>
                <p class="text-list">· Concurso</p>
              </li>
              <li>
                <p class="text-list">· Anticipo</p>
              </li>
              <li>
                <p class="text-list">· Cumplimiento</p>
              </li>
              <li>
                <p class="text-list">· Buena Calidad</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-sm-4 form-group">
          <div class="cards">
            <img class="image-card" src="../../../assets/images/surety/ico_fianza_fiscales.svg" alt="">
            <p class="text-card-orange">Fianzas Fiscales</p>
            <ul>
              <li>
                <p class="text-list">· Convenio de pagos</p>
              </li>
              <li>
                <p class="text-list">· Inconformidad</p>
              </li>
              <li>
                <p class="text-list">· Inconformidad C.O.P. IMSS</p>
              </li>
              <li>
                <p class="text-list">· Fiscales Capital Constitutivo IMSS</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-sm-4 form-group">
          <div class="cards">
            <img class="image-card" src="../../../assets/images/surety/ico_fianza_arrendamiento.svg" alt="">
            <p class="text-card-orange">Fianzas de Arrendamiento</p>
            <ul>
              <li>
                <p class="text-list">· Arrendamiento de Bienes inmuebles</p>
              </li>
              <li>
                <p class="text-list">· Arrendamiento de Bienes muebles</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-sm-4 form-group">
          <div class="cards">
            <img class="image-card" src="../../../assets/images/surety/ico_fianza_otras.svg" alt="">
            <p class="text-card-orange">Otras</p>
            <ul>
              <li>
                <p class="text-list">· Contingencias laborales</p>
              </li>
              <li>
                <p class="text-list">· Rifas y sorteos</p>
              </li>
              <li>
                <p class="text-list">· Permisos</p>
              </li>
              <li>
                <p class="text-list">· Concesiones</p>
              </li>
              <li>
                <p class="text-list">· Corredor o perito</p>
              </li>
              <li>
                <p class="text-list">· Boletaje IATA o Compañías de aviación</p>
              </li>
              <li>
                <p class="text-list">· Importación temporal revolvente</p>
              </li>
              <li>
                <p class="text-list">· Pago de fletes, arrastres y espuelas</p>
              </li>
              <li>
                <p class="text-list">· Exportación temporal</p>
              </li>
              <li>
                <p class="text-list">· Controversia arancelaria</p>
              </li>
              <li>
                <p class="text-list">· Agentes aduanales y corredores</p>
              </li>
              <li>
                <p class="text-list">· Convenios, concesiones, permisos y autorizaciones Y más...</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 form-group">
          <div class="container-orange">
            <p class="text-white-orange">Pregunta por nuestro plan de proveedores</p>
            <!-- <p class="text-white-small">Contáctanos: <span class="phone">cel. 442 191 0757</span> · contacto@grupogpi.mx</p> -->
            <p class="text-white-small">Contáctanos: <span class="phone"> · cel. (442) 239 2830</span> · contacto@grupogpi.mx</p>
            <!-- <p class="text-white-small">Contáctanos: <span class="phone"> · cel. (442) 628 2596</span></p>
            <p class="text-gray text-white-small"><span class="text-gray-blod"> · Celular y WhatsApp</span>: (442) 127 1244</p>
            <p class="text-gray text-white-small"><span class="text-gray-blod"> · Seguimiento de Siniestros: </span>(442) 219.1020</p>
            <p class="text-gray text-white-small"><span class="text-gray-blod"> · Correo electrónico</span>:  contacto@grupogpi.mx</p>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>