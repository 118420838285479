import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PromiseComponent } from '../promise/promise.component';
import { CarService } from 'src/app/pages/car/car.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { SettingsService } from 'src/app/services/services.index';

@Component({
  selector: 'app-car',
  templateUrl: './car.component.html',
  styleUrls: ['./car.component.css'],
  providers: [CarService]
})
export class CarComponent implements OnInit {

  formQuoter:FormGroup = Object.create(null);
  brands:any = [];
  subbrands:any = [];
  types:any = [];
  models:any = [];
  versions:any = [];
  sexs:any = [];
  checkboxZip:boolean = false;
  states:any = [];
  cities:any = [];
  neighborhoods:any = [];
  paquetes:any = [];
  frecuencias:any = [];
  loader:boolean = false;
  mensajes:any = [];
  local:any = [];
  cotizacion:any = {};
  onlyonetime:boolean = false;

  constructor(
    public router: Router,
    public _settings: SettingsService,
    private fb: FormBuilder,
    public _services: CarService,
    public _api: PromiseComponent,

    ) { 
      window.scrollTo(0, 0);
      this.formQuoter = this.fb.group({
        apellido_paterno: ['', Validators.required],
        apellido_materno: ['', Validators.required],
        ciudad: ['', Validators.required],
        clave: ['29999', Validators.required],
        colonia: ['', Validators.required],
        correo: ['', Validators.required],
        cp: ['', Validators.required],
        descripcion: ['', Validators.required],
        empresa: ['gpi', Validators.required],
        estado: ['', Validators.required],
        fecha_nacimiento: ['', Validators.required],
        forma_de_pago: ['', Validators.required],
        nombre: ['', Validators.required],
        marca: ['', Validators.required],
        modelo: ['', Validators.required],
        origen: ['avantthya', Validators.required],
        paquete: ['', Validators.required],
        poblacion: ['México', Validators.required],
        telefono: ['', Validators.required],
        sexo: ['', Validators.required],
        submarca: ['', Validators.required],
        check: [false, Validators.required],
        terminos: [false, Validators.required]
      });

      this._settings['settings']['results'] = [];
      this._settings['settings']['selected_quote'] = {};
      this._settings['settings']['issue'] = {};
      this._settings.saveSettings();
  
      this.sexs = [
        {descripcion: '', name: 'Selecciona un sexo',},
        {descripcion: 'F', name: 'Femenino' },
        {descripcion: 'M', name: 'Masculino' }
      ]
  
      this.getBrands();
      this.subbrands = [
        {descripcion: 'Selecciona un modelo', id: '0'}
      ];
      this.formQuoter.controls['submarca'].setValue(this.subbrands[0]['descripcion']);
      this.models = [
        {descripcion: 'Selecciona un año', id: '0'}
      ];
      this.formQuoter.controls['modelo'].setValue(this.models[0]['descripcion']);
      this.versions = [
        {descripcion: 'Selecciona una versión', id: '0'}
      ];
      this.formQuoter.controls['descripcion'].setValue(this.versions[0]['descripcion']);
      this.neighborhoods = [
        {colonia: 'Selecciona una colonia', id: '0'}
      ];
      this.formQuoter.controls['colonia'].setValue(this.neighborhoods[0]['colonia']);
      this.paquetes = [
        {paquete: 'Amplia', id: '01'},
        {paquete: 'Limitada', id: '03'},
        {paquete: 'RC', id: '04'}
      ];
      this.formQuoter.controls['paquete'].setValue(this.paquetes[0]['id']);
      this.frecuencias = [
        {frecuencia: 'Anual', id: 'C'},
        {frecuencia: 'Semestral', id: 'S'},
        {frecuencia: 'Trimestral', id: 'T'},
        {frecuencia: 'Mensual', id: 'M'}
      ];
      this.formQuoter.controls['forma_de_pago'].setValue(this.frecuencias[0]['id']);
  
    }

  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

    getBrands(){
      this.brands = [];
      this.subbrands = [];
      this.models = [];
      this.versions = [];
      this.subbrands = [
        {descripcion: 'Selecciona un modelo', id: '0'}
      ];
      this.formQuoter.controls['submarca'].setValue(this.subbrands[0]['descripcion']);
      this.models = [
        {descripcion: 'Selecciona un año', id: '0'}
      ];
      this.formQuoter.controls['modelo'].setValue(this.models[0]['descripcion']);
      this.versions = [
        {descripcion: 'Selecciona una versión', id: '0'}
      ];
      this.formQuoter.controls['descripcion'].setValue(this.versions[0]['descripcion']);
      this.loader = true;
      this._services.getBrands().then(response => {
        this.brands = response;
        this.brands.unshift({descripcion: 'Selecciona una marca', id: 'O'});
        this.formQuoter.controls['marca'].setValue(this.brands[0]['descripcion']);
        this.loader = false;
      }).catch(error => {
        console.log(error);
      });
    }
  
    get f(): { [key: string]: AbstractControl } {
      return this.formQuoter.controls;
    }
  
    getSubbrands(){
      this.subbrands = [];
      this.models = [];
      this.versions = [];
      this.models = [
        {descripcion: 'Selecciona un año', id: '0'}
      ];
      this.formQuoter.controls['modelo'].setValue(this.models[0]['descripcion']);
      this.versions = [
        {descripcion: 'Selecciona una versión', id: '0'}
      ];
      this.formQuoter.controls['descripcion'].setValue(this.versions[0]['descripcion']);
      this.loader = true;
      this._services.getSubbrands(this.formQuoter['value']['marca']).then(response => {
        this.subbrands = response;
        this.subbrands.unshift({descripcion: 'Selecciona un modelo', id: '0'});
        this.loader = false;
      }).catch(error => {
        console.log(error);
      });
    };
  
    getModels(){
      this.models = [];
      this.versions = [];
      this.versions = [
        {descripcion: 'Selecciona una versión', id: '0'}
      ];
      this.formQuoter.controls['descripcion'].setValue(this.versions[0]['descripcion']);
      this.loader = true;
      this._services.getModels(this.formQuoter['value']['marca'], this.formQuoter['value']['submarca']).then(response => {
        let models:any = response;
        for(let item of models){
          this.models.push({descripcion: item, id: item});
        }
        this.models.unshift({descripcion: 'Selecciona un año', id: '0'});
        this.loader = false;
      });
    }
  
    getVersions(){
      this.versions = [];
      this.loader = true;
      this._services.getVersions(this.formQuoter['value']['marca'], this.formQuoter['value']['submarca'], this.formQuoter['value']['modelo']).then(response => {
        this.versions = response;
        this.versions.unshift({descripcion: 'Selecciona una versión', id: '0'});
        this.loader = false;
      });
    }
  
    checkZip(){
      this.checkboxZip = this.formQuoter['value']['check'];
      this.cities = [
        {ciudad: 'Selecciona una ciudad', id_ciudad: '0'}
      ];
      this.formQuoter.controls['ciudad'].setValue(this.cities[0]['id_ciudad']);
      this.neighborhoods = [
        {colonia: 'Selecciona una colonia', id: '0'}
      ];
      this.formQuoter.controls['colonia'].setValue(this.neighborhoods[0]['colonia']);
      this.loader = true;
      this._services.getStates().then(response => {
        this.states = response;
        this.states.unshift({estado: 'Selecciona un estado', id_estado: '0'});
        this.formQuoter.controls['estado'].setValue(this.states[0]['id_estado']);
        this.loader = false;
      });
    }
  
    getNeighborhoodByZip(){
      if(this.formQuoter['value']['cp'].length == 5){
        this.loader = true;
        this.local['zip']= this.formQuoter['value']['cp'];
        this._settings.saveSettings();
        this._services.getNeighborhoodbyZip(this.formQuoter['value']['cp']).then((response:any) => {
          if(response.length > 0 ){
            this.neighborhoods = response;
            this.neighborhoods.unshift({colonia: 'Selecciona una colonia', id: '0'});
            this.formQuoter.controls['colonia'].setValue(this.neighborhoods[0]['colonia']);
            this.formQuoter.controls['estado'].setValue(this.neighborhoods[1]['c_estado']);
            this.formQuoter.controls['ciudad'].setValue(this.neighborhoods[1]['c_ciudad']);
            this.loader = false;  
          }else{
            Swal.fire('Error','No se encotró ningun estado con este código postal','error');
            this.loader = false;  
          }
        });
      }
    };
  
    getCities(){
      this.neighborhoods = [
        {colonia: 'Selecciona una colonia', id: '0'}
      ];
      this.formQuoter.controls['colonia'].setValue(this.neighborhoods[0]['colonia']);
      this.loader = true;
      this._services.getCities(this.formQuoter['value']['estado']).then(response => {
        this.cities = response;
        this.cities.unshift({ciudad: 'Selecciona una ciudad', id_ciudad: '0'});
        this.formQuoter.controls['ciudad'].setValue(this.cities[0]['id_ciudad']);
        this.loader = false;
      });
    }
  
    getNeighborhood(){
      this.loader = true;
      this._services.getNeighborhood(this.formQuoter['value']['estado'], this.formQuoter['value']['ciudad']).then(response => {
        this.neighborhoods = response;
        this.neighborhoods.unshift({colonia: 'Selecciona una colonia', id: '0'});
        this.formQuoter.controls['colonia'].setValue(this.neighborhoods[0]['colonia']);
        this.loader = false;
      });
    }
  
    getZipByNeighborhood(){
      if(!this.formQuoter['value']['cp']){
        for(let item of this.neighborhoods){
          if(this.formQuoter['value']['colonia'] == item['colonia']){
            this.formQuoter.controls['cp'].setValue(item['cp']);
          }
        }
      }
    }
  
    onSubmit(){    
      if(this.formQuoter['value']['marca'] == '' || this.formQuoter['value']['marca'] == undefined || this.formQuoter['value']['marca'] == 'Selecciona una marca'){
        Swal.fire('Error', 'Selecciona una marca', 'error');
        return;
      }
      if(this.formQuoter['value']['submarca'] == '' || this.formQuoter['value']['submarca'] == undefined || this.formQuoter['value']['submarca'] == 'Selecciona un modelo'){
        Swal.fire('Error', 'Selecciona una submarca', 'error');
        return;
      }
      if(this.formQuoter['value']['modelo'] == '' || this.formQuoter['value']['modelo'] == undefined || this.formQuoter['value']['modelo'] == 'Selecciona un año'){
        Swal.fire('Error', 'Selecciona un año', 'error');
        return;
      }
      if(this.formQuoter['value']['descripcion'] == '' || this.formQuoter['value']['descripcion'] == undefined || this.formQuoter['value']['descripcion'] == 'Selecciona una versión'){
        Swal.fire('Error', 'Selecciona una versión', 'error');
        return;
      }
      if(this.formQuoter['value']['fecha_nacimiento'] == '' || this.formQuoter['value']['fecha_nacimiento'] == undefined){
        Swal.fire('Error', 'Selecciona una fecha de nacimiento', 'error');
        return;
      }else{
        let fecha1 = new Date();
        let fecha2 = new Date(this.formQuoter['value']['fecha_nacimiento']);
        let fecha3 = new Date(1910, 11, 31);
        if(fecha1 < fecha2){
          Swal.fire('Error', 'Fecha de nacimiento inválida', 'error');
          return;
        }else{
          if(fecha2 < fecha3){
            Swal.fire('Error', 'Fecha de nacimiento inválida', 'error');
            return;
          }else{
          }
        }
      }
      if(this.formQuoter['value']['sexo'] == '' || this.formQuoter['value']['sexo'] == undefined || this.formQuoter['value']['sexo'] == 'X'){
        Swal.fire('Error', 'Selecciona un sexo', 'error');
        return;
      }
      if(this.checkboxZip){
        if(this.formQuoter['value']['estado'] == '' || this.formQuoter['value']['estado'] == undefined || this.formQuoter['value']['estado'] == 0){
          Swal.fire('Error', 'Selecciona un estado', 'error');
          return;
        }
        if(this.formQuoter['value']['ciudad'] == '' || this.formQuoter['value']['ciudad'] == undefined || this.formQuoter['value']['ciudad'] == 0){
          Swal.fire('Error', 'Selecciona una ciudad', 'error');
          return;
        }
      }else{
        if(this.formQuoter['value']['cp'] == '' || this.formQuoter['value']['cp'] == undefined || this.formQuoter['value']['cp'] == null){
          Swal.fire('Error', 'Ingresa un código postal', 'error');
          return;
        }else{
          if(this.formQuoter['value']['cp'].length != 5){
            Swal.fire('Error', 'El código postal debe ser de 5 dígitos.', 'error');
            return;
          }
        }
      }
      if(this.formQuoter['value']['coloia'] == '' || this.formQuoter['value']['colonia'] == undefined || this.formQuoter['value']['colonia'] == 'Selecciona una colonia'){
        Swal.fire('Error', 'Selecciona una colonia', 'error');
        return;
      }
      if(this.formQuoter['value']['nombre'] == '' || this.formQuoter['value']['nombre'] == undefined){
        Swal.fire('Error', 'Ingresa tu nombre', 'error');
        return;
      }
      if(this.formQuoter['value']['apellido_paterno'] == '' || this.formQuoter['value']['apellido_paterno'] == undefined){
        Swal.fire('Error', 'Ingresa tu apellido paterno', 'error');
        return;
      }
      if(this.formQuoter['value']['apellido_materno'] == '' || this.formQuoter['value']['apellido_materno'] == undefined){
        Swal.fire('Error', 'Ingresa tu apellido materno', 'error');
        return;
      }
      if(this.formQuoter['value']['correo'] == '' || this.formQuoter['value']['correo'] == undefined){
        Swal.fire('Error', 'Ingresa tu correo electrónico', 'error');
        return;
      }
      if(this.formQuoter['value']['telefono'] == '' || this.formQuoter['value']['telefono'] == undefined){
        Swal.fire('Error', 'Ingresa un número de teléfono', 'error');
        return;
        }else{
        if(this.formQuoter['value']['telefono'].length != 10){
          Swal.fire('Error', 'Tu teléfono debe tener 10 dígitos', 'error');
          return;
          }
      }
      if(this.formQuoter['value']['terminos'] == false || !this.formQuoter['value']['terminos']){
        Swal.fire('Error', 'Acepta los términos y condiciones', 'error');
        return;
      }
  
      this.versions.forEach((item:any) => {
        if (item.descripcion == this.formQuoter.get('descripcion')?.value){
          this.formQuoter.value['clave'] = item.clave;
        }
      });
      this.cotizacion['address_1'] = '-';
      this.cotizacion['agencia'] = 1;
      this.cotizacion['car_serial'] = 'numSerial';
      this.cotizacion['civil_state'] = 2;
      this.cotizacion['cob'] = 'Amplia';
      this.cotizacion['country'] = 'México';
      this.cotizacion['descuento'] = 0;
      this.cotizacion['first_name'] = 'Prueba';
      this.cotizacion['form_data_id'] = '';
      this.cotizacion['lada'] = '55';
      this.cotizacion['last_name'] = 'Prueba';
      this.cotizacion['multianual'] = 0;
      this.cotizacion['phone'] = '11111111111';
      this.cotizacion['quoteFrecuency'] = 12;
      this.cotizacion['quotePaymentMethod'] = '';
      this.cotizacion['rfc'] = 'RFC000000';
      this.cotizacion['sa_factura'] = '';
      this.cotizacion['second_last_name'] = 'Prueba';
      this.cotizacion['tipo_carga'] = '';
      this.cotizacion['tipo_sa'] = '4452';
      this.cotizacion['urlname'] = this._settings['settings']['urlname'];
      this.cotizacion['xml'] = '';
      this.cotizacion['car_brand'] = this.formQuoter['value']['marca'];
      this.cotizacion['car_year'] = this.formQuoter['value']['modelo'];
      this.cotizacion['car_model'] = this.formQuoter['value']['descripcion'];
      this.cotizacion['car_sex'] = this.formQuoter['value']['sexo'];
      this.cotizacion['email'] = this.formQuoter['value']['correo'];
      this.cotizacion['zip_code'] = this.formQuoter['value']['cp'];
      this.cotizacion['car_clases'] = 'Autos';
      this._settings['settings']['cotizacion'] = this.cotizacion;
      if(this.onlyonetime ==false){
        this._api.sendEmailAuto(this.cotizacion);
        this.onlyonetime = true
        console.log('------ya se envio email-----d',this.onlyonetime)
      }else{
        console.log('---debe recargar la página si quiere enviar el correo---',this.onlyonetime)
      }
      this._settings['settings']['quote'] = this.formQuoter['value'];
      this._settings.saveSettings();
      this.router.navigate(['/comparador-auto']);
    }
  
    goToPage(param){
      this.router.navigate(['/' + param]);
    }
    

}
