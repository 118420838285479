import { Component, OnInit } from '@angular/core';
import { CarService } from '../car/car.service';
import { SettingsService } from 'src/app/services/settings/ajustes';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-car-print',
  templateUrl: './car-print.component.html',
  styleUrls: ['./car-print.component.css'],
  providers: [CarService]
})
export class CarPrintComponent implements OnInit {

  data:any = [];
  loader:boolean = false;
  provider:string = '';
  policy:string = '';
  base64:any;
  url:string = '';

  constructor(
    private _settings: SettingsService,
    private _router: Router,
    public _services: CarService,

  ) { 
    window.scrollTo(0, 0);
    this.data = this._settings['settings'];
    this.provider = this.data['selected_quote']['aseguradora'];
    switch (this.provider) {
      case 'ana':
        this.policy = this.data['issue']['transacciones']['transaccion']['poliza']['@id'];
        this.data['issue']['cotizacion_id'] = this.data['selected_quote']['raw_response']['id'];
        this.url = environment.URL_ANA;
        break;
      case 'chubb':
        this.policy = this.data['issue']['EMI']['POL']['CVE'] + ' ' + this.data['issue']['EMI']['POL']['POL'];
        this.data['issue']['cotizacion_id'] = this.data['selected_quote']['raw_response']['id'];
        this.url = environment.URL_CHUBB;
        break;
      case 'gnp':
        this.policy = this.data['issue']['EMISION']['SOLICITUD']['NUM_POLIZA'];
        this.data['issue']['cotizacion_id'] = this.data['selected_quote']['raw_response']['id'];
        this.url = environment.URL_GNP;
        break;
      case 'hdi':
        this.policy = this.data['issue']['idPoliza'];
        this.url = environment.URL_HDI;
        break;
      case 'ps':
        this.policy = this.data['issue']['pol_clave'];
        this.base64 = this.data['issue']['poliza_pdf'];
        this.sendEmail();
        break;
      default:
        break;
    }
  }

  ngOnInit(): void {
  }

  sendEmail(){
    let data = {
      base64: this.provider == 'ana' ? '': this.data['issue']['poliza_pdf'],
      correo: this.data['quote']['correo'],
      nombre: this.data['quote']['nombre'] + ' ' + this.data['quote']['apellido_paterno'] + ' ' +this.data['quote']['apellido_materno'],
      poliza: this.data['issue']['pol_clave'],
      asunto: 'Emisión GPI primero seguros',
      origen: 'gpi'
    }
    this._services.sendEmailEmision(data).then((response:any) => {
      if(response['sent'] = 'sent'){
        Swal.fire('Listo','Póliza enviada por correo.', 'success');
      }else{
        Swal.fire('Error','Error al enviar la póliza por correo electrónico.','error')
      }
    }).catch(error => {
      console.log(error);
      Swal.fire('Error','Ocurrió un error','error')
      this.loader = false;
    });
  }
  // downloadPDF(pdf:any) {
  //   const linkSource = `data:application/pdf;base64,${pdf}`;
  //   const downloadLink = document.createElement("a");
  //   const fileName = this.data['issue']['pol_clave'] + ".pdf";
  //   downloadLink.href = linkSource;
  //   downloadLink.download = fileName;
  //   downloadLink.click();
  // }

  downloadPdfAna(value:number){
    this.loader = true;
    this._services.downloadPdfAna(this.url, this.data['issue']['cotizacion_id']).then((response:any) => {
      let link = response['transacciones']['transaccion']['poliza']['@link'];
      window.open(link, "_blank");
      if(value == 1){
        this.base64 = link;
        this.sendEmail();
      }
      this.loader = false;
    }).catch(error => {
      console.log(error);
      Swal.fire('Error','Ocurrió un error', 'error');
      this.loader = false;
    });
  }

  downloadPdfChubb(){
    this.loader = true;
    this._services.downloadPdfAna(this.url, this.data['issue']['cotizacion_id']).then((response:any) => {
      let link = response['IM']['PATHPDF'];
      window.open(link, "_blank");
      this.loader = false;
    }).catch(error => {
      console.log(error);
      Swal.fire('Error','Ocurrió un error', 'error');
      this.loader = false;
    });
  }

  downloadPdfGnp(){
    this.loader = true;
    this._services.downloadPdfAna(this.url, this.data['issue']['cotizacion_id']).then((response:any) => {
      let link = response['RESULTADO']['URL_DOCUMENTO'];
      window.open(link, "_blank");
      this.loader = false;
    }).catch(error => {
      console.log(error);
      Swal.fire('Error','Ocurrió un error', 'error');
      this.loader = false;
    });
  }

  downloadPdfHdi(){
    this.loader = true;
    this._services.downloadPdfHdi(this.url, this.data['issue']['cotizacion_id']).subscribe(
      (data: Blob) => {
        let file = new Blob([data], { type: 'application/pdf' })
        let fileURL = URL.createObjectURL(file);
        
        // if you want to open PDF in new tab
        window.open(fileURL); 
        const downloadLink = document.createElement('a');
        downloadLink.href = fileURL; 
        // downloadLink.target = '_blank';
        downloadLink.download = this.policy + '.pdf';
        // document.body.appendChild(downloadLink);
        downloadLink.click();
        this.loader = false;
      },
      (error) => {
        console.log('getPDF error: ',error);
        Swal.fire('Error','Ocurrió un error', 'error');
      }
    );
  }

  downloadPdfPs(pdf:any) {
    this.loader = true;
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = this.policy + ".pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    this.loader = false;
  }

  redirect(value:string){
    this._settings['settings']['quote'] = {};
    this._settings['settings']['results'] = [];
    this._settings['settings']['selected_quote'] = {};
    this._settings['settings']['issue'] = {};
    this._settings.saveSettings();
    this._router.navigate(['/' + value]);    
  }


}
