import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PromiseComponent } from '../promise/promise.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  contact = {
    nombre: '',
    email: '',
    contacto: '',
    asunto: '',
    mensaje: ''
  }

  showButtonCar;

  showAlertError;
  showAlertEmail;
  showAlertSuccess;

  constructor(
    public _api: PromiseComponent,
    private router: Router
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.showButtonCar = true;

    this.showAlertError = false;
    this.showAlertEmail = false;
    this.showAlertSuccess = false;
  }

  validateEmail(email){
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  sendContact(){
    if(this.contact.nombre && this.contact.email && this.contact.contacto && this.contact.asunto && this.contact.mensaje){
      if(!this.validateEmail(this.contact['email'])){
        this.showAlertEmail = true;
        return;
      }
      this._api.send_contact(this.contact).then(response=>{
        if(response['status'] == 'sent'){
          this.showAlertSuccess = true;
        }
        else if(response['status'] == 500){
          this.showAlertError = true;
        }
      }).catch(error=>{
        console.log(error);
      })
    }
    else{
      this.showAlertError = true;
    }
  };

  cancel(){
    this.showAlertError = false;
    this.showAlertEmail = false;
    this.showAlertSuccess = false;
  }

  goToPage(param){
    this.router.navigate(['/' + param]);
  };

  // goToPage(){
  //   this.router.navigate(['/cotizador-autos'])
  // }

}
