<!-- <div class="container-main" *ngIf="!showIssue">
  <div class="container">
    <div class="container-white">
      <div class="row">
        <div class="col-12 form-group">
          <p class="title-car">SEGURO DE AUTO</p>
          <p class="subtitle-car">¡Ya casi! Solo faltan estos datos</p>
        </div>
      </div>
      <div class="row" *ngIf="showOne">
        <div class="col-3 form-group">
          <p class="number-progress-activate">1</p>
        </div>
        <div class="col-3 form-group">
          <p class="number-progress">2</p>
        </div>
        <div class="col-3 form-group">
          <p class="number-progress">3</p>
        </div>
        <div class="col-3 form-group">
          <p class="number-progress">4</p>
        </div>
        <div class="col-12 form-group">
          <div class="progress" style="height: 4px;">
            <div class="progress-bar" role="progressbar" [ngStyle]="{'width':progressBar}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
        <div class="col-12 form-group">
          <hr>
          <p class="subtitle-car">Datos del asegurado</p>
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Nombre</p>
          <input class="form-control" type="text" [(ngModel)]="emision.nombre_asegurado">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Apellido Paterno</p>
          <input class="form-control" type="text" [(ngModel)]="emision.apaterno_asegurado">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Apellido Materno</p>
          <input class="form-control" type="text" [(ngModel)]="emision.amaterno_asegurado">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Fecha de Nacimiento</p>
          <input class="form-control" type="text" [(ngModel)]="birthdateInsured">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Sexo</p>
          <select class="form-control" [(ngModel)]="emision.sexo_asegurado">
            <option *ngFor="let item of sexs" [value]="item.name">
              {{item.name}}
            </option>
          </select>
        </div>
        <div class="col-12 form-group">
          <p class="text-form">RFC</p>
          <input class="form-control" type="phone" [(ngModel)]="emision.rfc_asegurado">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Estado civil</p>
          <select class="form-control" [(ngModel)]="emision.edoCivil_asegurado">
            <option *ngFor="let item of civils" [value]="item.name">
              {{item.name}}
            </option>
          </select>
        </div>
        <div class="col-12 col-sm-6 form-group">
          <button class="button-white" (click)="goToPage('comparador-auto')">Regresar</button>
        </div>
        <div class="col-12 col-sm-6 form-group">
          <button class="button-orange" (click)="validity(2)">Siguiente</button>
        </div>
      </div>
      <div class="row" *ngIf="showTwo">
        <div class="col-3 form-group">
          <img class="image-check" src="../../../assets/images/quotation/ico_check.svg" alt="">
        </div>
        <div class="col-3 form-group">
          <p class="number-progress-activate">2</p>
        </div>
        <div class="col-3 form-group">
          <p class="number-progress">3</p>
        </div>
        <div class="col-3 form-group">
          <p class="number-progress">4</p>
        </div>
        <div class="col-12 form-group">
          <div class="progress" style="height: 4px;">
            <div class="progress-bar progress-green" role="progressbar" [ngStyle]="{'width':progressBarGreen}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            <div class="progress-bar" role="progressbar" [ngStyle]="{'width':progressBar}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
        <div class="col-12 form-group">
          <hr>
          <p class="subtitle-car">Datos del Contratante</p>
        </div>
        <div class="col-12 form-group">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="defaultCheck1" [(ngModel)]="checkContractor" (change)="getContractor()">
            <label class="form-check-label text-form-check" for="defaultCheck1">
              ¿El asegurado y el contratante son los mismos?
            </label>
          </div>
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Nombre</p>
          <input class="form-control" type="text" [(ngModel)]="emision.nombre_contratante">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Apellido Paterno</p>
          <input class="form-control" type="text" [(ngModel)]="emision.apaterno_contratante">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Apellido Materno</p>
          <input class="form-control" type="text" [(ngModel)]="emision.amaterno_contratante">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Fecha de Nacimiento</p>
          <input class="form-control" type="date" [(ngModel)]="birthdateContractor">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Sexo</p>
          <select class="form-control" [(ngModel)]="emision.sexo_contratante">
            <option *ngFor="let item of sexs" [value]="item.name">
              {{item.name}}
            </option>
          </select>
        </div>
        <div class="col-12 form-group">
          <p class="text-form">RFC</p>
          <input class="form-control" type="phone" [(ngModel)]="emision.rfc_contratante">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Estado civil</p>
          <select class="form-control" [(ngModel)]="emision.edoCivil_contratante">
            <option *ngFor="let item of civils" [value]="item.name">
              {{item.name}}
            </option>
          </select>
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Correo electrónico</p>
          <input class="form-control" type="email" [(ngModel)]="emision.email_contratante">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Teléfono</p>
          <input class="form-control" type="phone" [(ngModel)]="emision.numero_1_contratante">
        </div>
        <div class="col-12 col-sm-6 form-group">
          <button class="button-white" (click)="next(1)">Regresar</button>
        </div>
        <div class="col-12 col-sm-6 form-group">
          <button class="button-orange" (click)="validity(3)">Siguiente</button>
        </div>
      </div>
      <div class="row" *ngIf="showThree">
        <div class="col-3 form-group">
          <img class="image-check" src="../../../assets/images/quotation/ico_check.svg" alt="">
        </div>
        <div class="col-3 form-group">
          <img class="image-check" src="../../../assets/images/quotation/ico_check.svg" alt="">
        </div>
        <div class="col-3 form-group">
          <p class="number-progress-activate">3</p>
        </div>
        <div class="col-3 form-group">
          <p class="number-progress">4</p>
        </div>
        <div class="col-12 form-group">
          <div class="progress" style="height: 4px;">
            <div class="progress-bar progress-green" role="progressbar" [ngStyle]="{'width':progressBarGreen}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            <div class="progress-bar" role="progressbar" [ngStyle]="{'width':progressBar}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
        <div class="col-12 form-group">
          <hr>
          <p class="subtitle-car">Datos del Automóvil</p>
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Marca</p>
          <input class="form-control" type="text" [(ngModel)]="cotizacion.car_brand" [disabled]="true">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Año</p>
          <input class="form-control" type="text" [(ngModel)]="cotizacion.car_year" [disabled]="true">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Modelo</p>
          <input class="form-control" type="text" [(ngModel)]="cotizacion.model_name" [disabled]="true">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Placas</p>
          <input class="form-control" type="text" [(ngModel)]="emision.placas">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Número de serie</p>
          <input class="form-control" type="text" [(ngModel)]="emision.numeroSerie">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Número de motor</p>
          <input class="form-control" type="text" [(ngModel)]="emision.numeroMotor">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Calle</p>
          <input class="form-control" type="text" [(ngModel)]="emision.ubicacion_direccion">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Número</p>
          <input class="form-control" type="text" [(ngModel)]="emision.numero_direccion">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Colonia</p>
          <select class="form-control" [(ngModel)]="emision.colonia_direccion">
            <option *ngFor="let item of suburbs" [value]="item.name">
              {{item.name}}
            </option>
          </select>
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Ciudad</p>
          <input class="form-control" type="text" [(ngModel)]="cotizacion.ddMun" [disabled]="true">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Estado</p>
          <input class="form-control" type="text" [(ngModel)]="emision.estado_direccion" [disabled]="true">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Código Postal</p>
          <input class="form-control" type="text" [(ngModel)]="emision.cp_direccion" [disabled]="true">
        </div>
        <div class="col-12 col-sm-6 form-group">
          <button class="button-white" (click)="next(2)">Regresar</button>
        </div>
        <div class="col-12 col-sm-6 form-group">
          <button class="button-orange" (click)="validity(4)">Siguiente</button>
        </div>
      </div>
      <div class="row" *ngIf="showFour">
        <div class="col-3 form-group">
          <img class="image-check" src="../../../assets/images/quotation/ico_check.svg" alt="">
        </div>
        <div class="col-3 form-group">
          <img class="image-check" src="../../../assets/images/quotation/ico_check.svg" alt="">
        </div>
        <div class="col-3 form-group">
          <img class="image-check" src="../../../assets/images/quotation/ico_check.svg" alt="">
        </div>
        <div class="col-3 form-group">
          <p class="number-progress-activate">4</p>
        </div>
        <div class="col-12 form-group">
          <div class="progress" style="height: 4px;">
            <div class="progress-bar progress-green" role="progressbar" [ngStyle]="{'width':progressBarGreen}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
            <div class="progress-bar" role="progressbar" [ngStyle]="{'width':progressBar}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
        <div class="col-12 form-group">
          <hr>
          <p class="subtitle-car">Datos de la tarjeta</p>
        </div>
        <div class="col-12 col-sm-6 form-group">
          <button class="button-white" (click)="next(3)">Regresar</button>
        </div>
        <div class="col-12 col-sm-6 form-group">
          <button class="button-orange" (click)="toEmit()">Emitir</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-alert" *ngIf="showAlertError">
  <div class="container-modal">
    <p class="title-modal-orange">Ha ocurrido un error</p>
    <p>{{ messageError }}</p>
    <button class="button-modal" (click)="cancel()">Aceptar</button>
  </div>
</div>
<div class="container-orange" *ngIf="showIssue">
  <div class="container">
    <div class="row">
      <div class="col-12 form-group">
        <div class="container-border">
          <p class="title-white">¡Felicidades!</p>
          <p class="text-white">Ya tienes un Seguro de Gastos Médicos Mayores</p>
        </div>
      </div>
      <div class="container-white">
        <div class="col-12 form-group">
          <p class="text-form">Tu póliza se mandará al siguiente correo electrónico:</p>
          <p class="text-purple">{{ cotizacion.email }}</p>
          <button class="button-green" (click)="print()">Descargar póliza</button>
          <p class="text-orange">Datos de la póliza</p>
          <p class="subtitle-car">No. de póliza</p>
          <p class="text-form">{{ policyNumber }}</p>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 form-group">
            <button class="button-white" (click)="goToPage('inicio')">Salir del cotizador</button>
          </div>
          <div class="col-12 col-sm-6 form-group">
            <button class="button-purple" (click)="goToPage('cotizador-auto')">Cotizar otro seguro</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="container-main">
  <div class="container">
    <div class="row container-white">
      <div class="col-12 container-grey">
        <form [formGroup]="formIssue" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-12">
              <p class="text-purple">Datos del vehículo</p>
            </div>
            <div class="col-12">
              <div class="col-sm-4 offset-md-4">
                <img *ngIf="data.selected_quote.aseguradora == 'ana'" class="logo" src="../../../assets/insurers/Logos_aseguradoras_Ana.svg" alt="">
                <img *ngIf="data.selected_quote.aseguradora == 'chubb'" class="logo" src="../../../assets/insurers/Logos_aseguradoras_Chubb.svg" alt="">
                <img *ngIf="data.selected_quote.aseguradora == 'gnp'" class="logo" src="../../../assets/insurers/Logos_aseguradoras_GNP.svg" alt="">
                <img *ngIf="data.selected_quote.aseguradora == 'hdi'" class="logo" src="../../../assets/insurers/Logos_aseguradoras_HDI.svg" alt="">
                <img *ngIf="data.selected_quote.aseguradora == 'ps'" class="logo" src="../../../assets/insurers/Logos_aseguradoras_Primero.svg" alt="">
                <img *ngIf="data.selected_quote.aseguradora == 'qualitas'" class="logo" src="../../../assets/insurers/Logos_aseguradoras_Qualitas.svg" alt="">
              </div>
            </div>
          </div>

          <div class="row" *ngIf="provider == 'chubb' || provider == 'qualitas'">
            <div class="col-12 col-sm-4">
              <label>Nombre:</label>
              <input class="input-form form-control" type="text" formControlName="cte_nombre" style="background-color: #ececec;" readonly>
            </div>
            <div class="col-12 col-sm-4">
              <label>Apellido paterno:</label>
              <input class="input-form form-control" type="text" formControlName="cte_apepat" style="background-color: #ececec;" readonly>
            </div>
            <div class="col-12 col-sm-4">
              <label>Apellido materno:</label>
              <input class="input-form form-control" type="text" formControlName="cte_apemat" style="background-color: #ececec;" readonly>
            </div>
            <div class="col-12 col-sm-4">
              <label>Fecha de nacimiento:</label>
              <input class="input-form form-control" type="date" formControlName="fecha_nacimiento" style="background-color: #ececec;" readonly>
            </div>
            <div class="col-12 col-sm-4">
              <label>Correo electrónico:</label>
              <input class="input-form form-control" type="email" formControlName="email" style="background-color: #ececec;" readonly>
            </div>
            <div class="col-12 col-sm-4">
              <label>Número de teléfono:</label>
              <input class="input-form form-control" type="phone" formControlName="tel_num" style="background-color: #ececec;" readonly>
            </div>
            <div class="col-12 col-sm-3">
              <label>Género:</label>
              <input *ngIf="formIssue.value.sexo == 'F'" class="input-form form-control" type="text"
              placeholder="Femenino" style="background-color: #ececec;" readonly>
              <input *ngIf="formIssue.value.sexo == 'M'" class="input-form form-control" type="text" 
              placeholder="Masculino" style="background-color: #ececec;" readonly>
            </div>
            <div class="col-12 col-sm-3">
              <label>RFC(con homoclave):</label>
              <input class="input-form form-control" type="text" formControlName="rfc">
              <small *ngIf="rfcError" class="small-red">RFC es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-6">
              <label>Calle y número:</label>
              <input class="input-form form-control" type="text" formControlName="dir_calle_num">
              <small *ngIf="addressError" class="small-red">Dirección es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Numero de serie:</label>
              <input class="input-form form-control" type="text" formControlName="aut_no_serie">
              <small *ngIf="serieError" class="small-red">Serie es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Numero de motor:</label>
              <input class="input-form form-control" type="text" formControlName="aut_no_motor">
            </div>
            <div class="col-12 col-sm-4">
              <label>Número de placas:</label>
              <input class="input-form form-control" type="text" formControlName="aut_placas">
            </div>

            <div class="row">
              <div class="col-12 col-sm-6">
                <button class="button-white" (click)="redirect()">Regresar</button>
              </div>
              <div class="col-12 col-sm-6">
                <button class="button button-orange" type="submit">Emitir</button>
              </div>
            </div>
          </div>


          <!-- ana -->
          <div class="row" *ngIf="provider == 'ana'">
            <div class="col-12 col-sm-4">
              <label>Nombre:</label>
              <input class="input-form form-control" type="text" formControlName="nombre" style="background-color: #ececec;" readonly>
              <small *ngIf="nameError" class="small-red">Nombre es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Apellido paterno:</label>
              <input class="input-form form-control" type="text" formControlName="paterno" style="background-color: #ececec;" readonly>
              <small *ngIf="lastError" class="small-red">Apellido paterno es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Apellido materno:</label>
              <input class="input-form form-control" type="text" formControlName="materno" style="background-color: #ececec;" readonly>
              <small *ngIf="secondError" class="small-red">Apellido materno es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-6">
              <label>RFC(con homoclave):</label>
              <input class="input-form form-control" type="text" formControlName="rfc">
              <small *ngIf="rfcError" class="small-red">RFC es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-6">
              <label>CURP :</label>
              
              <input class="input-form form-control" type="text" formControlName="curp">
              <small *ngIf="curpError" class="small-red">CURP es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Colonia</label>
              <select class="input-form form-control" formControlName="colonia">
                <option *ngFor="let item of neighborhoods" [value]="item.text">
                  {{item.text}}
                </option>
              </select>
              <small *ngIf="neighborhoodError" class="small-red">Colonia es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Calle:</label>
              <input class="input-form form-control" type="text" formControlName="calle">
              <small *ngIf="addressError" class="small-red">Dirección es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Número exterior:</label>
              <input class="input-form form-control" type="text" formControlName="numeroexterior">
              <small *ngIf="addressError" class="small-red">Dirección es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Número interior:</label>
              <input class="input-form form-control" type="text" formControlName="numerointerior">
            </div>
            <!-- <div class="col-12 col-sm-8">
              <label>Tipo de persona :</label>
              <select class="input-form form-control" formControlName="tipopersona">
                <option *ngFor="let item of tipoPersona" [value]="item.id">
                  {{item.name}}
                </option>
              </select>
            </div> -->
            <div class="col-12 col-sm-4">
              <label>Número de placas:</label>
              <input class="input-form form-control" type="text" formControlName="placas">
              <small *ngIf="platesError" class="small-red">Placas es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Numero de serie:</label>
              <input class="input-form form-control" type="text" formControlName="serie">
              <small *ngIf="serieError" class="small-red">Serie es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Numero de motor:</label>
              <input class="input-form form-control" type="text" formControlName="motor">
              <small *ngIf="engineError" class="small-red">Motor es un campo requerido.</small>
            </div>

            <div class="row">
              <div class="col-12 col-sm-6">
                <button class="button-white" (click)="redirect()">Regresar</button>
              </div>
              <div class="col-12 col-sm-6">
                <button class="button button-orange" type="submit">Emitir</button>
              </div>
            </div>
          </div>

          <!-- GNP -->
          <div class="row" *ngIf="provider == 'gnp'">
            <div class="col-12 col-sm-4">
              <label>Nombre:</label>
              <input class="input-form form-control" type="text" formControlName="nombre" style="background-color: #ececec;" readonly>
              <small *ngIf="nameError" class="small-red">Nombre es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Apellido paterno:</label>
              <input class="input-form form-control" type="text" formControlName="apellido_paterno" style="background-color: #ececec;" readonly>
              <small *ngIf="lastError" class="small-red">Apellido paterno es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Apellido materno:</label>
              <input class="input-form form-control" type="text" formControlName="apellido_materno" style="background-color: #ececec;" readonly>
              <small *ngIf="secondError" class="small-red">Apellido materno es un campo requerido.</small>
            </div>
            <!-- <div class="col-12 col-sm-4">
              <label>Género:</label>
              <input *ngIf="formIssue.value.sexo == 'F'" class="input-form form-control" type="text"
              placeholder="Femenino" style="background-color: #ececec;" readonly>
              <input *ngIf="formIssue.value.sexo == 'M'" class="input-form form-control" type="text" 
              placeholder="Masculino" style="background-color: #ececec;" readonly>
            </div> -->
            <div class="col-12 col-sm-4">
              <label>RFC(con homoclave):</label>
              <input class="input-form form-control" type="text" formControlName="rfc">
              <small *ngIf="rfcError" class="small-red">RFC es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Colonia</label>
              <select class="input-form form-control" formControlName="colonia">
                <option *ngFor="let item of neighborhoods" [value]="item.CLAVE">
                  {{item.NOMBRE}}
                </option>
              </select>
              <small *ngIf="neighborhoodError" class="small-red">Colonia es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Calle:</label>
              <input class="input-form form-control" type="text" formControlName="calle">
              <small *ngIf="addressError" class="small-red">Dirección es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Número exterior:</label>
              <input class="input-form form-control" type="text" formControlName="numero_exterior">
              <small *ngIf="addressError" class="small-red">Dirección es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Número interior:</label>
              <input class="input-form form-control" type="text" formControlName="numero_interior">
              <!-- <small *ngIf="addressError" class="small-red">Dirección es un campo requerido.</small> -->
            </div>

            <div class="col-12 col-sm-4">
              <label>Numero de serie:</label>
              <input class="input-form form-control" type="text" formControlName="serie">
              <small *ngIf="serieError" class="small-red">Serie es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Numero de motor:</label>
              <input class="input-form form-control" type="text" formControlName="motor">
              <small *ngIf="engineError" class="small-red">Motor es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Número de placas:</label>
              <input class="input-form form-control" type="text" formControlName="placas">
              <small *ngIf="platesError" class="small-red">Placas es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Razón social:</label>
              <input class="input-form form-control" type="text" formControlName="razon_social">
              <small *ngIf="platesError" class="small-red">Razón social es un campo requerido.</small>
            </div>


            <div class="col-12 col-sm-6">
              <label>Regimen fiscal (Persona física):</label>
              <select class="input-form form-control" formControlName="regimen_fiscal">
                <option *ngFor="let item of regimefiscal" [value]="item.CLAVE">
                  {{item.NOMBRE}}
                </option>
              </select>
              <small *ngIf="neighborhoodError" class="small-red">Regimen fiscal es un campo requerido.</small>
            </div>

            <div class="row">
              <div class="col-12 col-sm-6">
                <button class="button-white" (click)="redirect()">Regresar</button>
              </div>
              <div class="col-12 col-sm-6">
                <button class="button button-orange" type="submit">Emitir</button>
              </div>
            </div>
          </div>
          
          <!-- HDI -->
          <div class="row" *ngIf="provider == 'hdi'">
            <div class="col-12 col-sm-4">
              <label>Nombre:</label>
              <input class="input-form form-control" type="text" formControlName="PrimerNombre" style="background-color: #ececec;" readonly>
              <small *ngIf="nameError" class="small-red">Nombre es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Apellido paterno:</label>
              <input class="input-form form-control" type="text" formControlName="ApellidoPaterno" style="background-color: #ececec;" readonly>
              <small *ngIf="lastError" class="small-red">Apellido paterno es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Apellido materno:</label>
              <input class="input-form form-control" type="text" formControlName="ApellidoMaterno" style="background-color: #ececec;" readonly>
              <small *ngIf="secondError" class="small-red">Apellido materno es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-3">
              <label>Numero de serie:</label>
              <input class="input-form form-control" type="text" formControlName="numeroSerie">
              <small *ngIf="serieError" class="small-red">Serie es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-3">
              <label>Numero de motor:</label>
              <input class="input-form form-control" type="text" formControlName="numeroMotor">
              <small *ngIf="engineError" class="small-red">Motor es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-3">
              <label>Número de placas:</label>
              <input class="input-form form-control" type="text" formControlName="placas">
              <small *ngIf="platesError" class="small-red">Placas es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-3">
              <label>RFC(con homoclave):</label>
              <input class="input-form form-control" type="text" formControlName="RFC">
              <small *ngIf="rfcError" class="small-red">RFC es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Colonia</label>
              <select class="input-form form-control" formControlName="colonia">
                <option *ngFor="let item of neighborhoods" [value]="item.Colonia">
                  {{item.Colonia}}
                </option>
              </select>
              <small *ngIf="neighborhoodError" class="small-red">Colonia es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Calle:</label>
              <input class="input-form form-control" type="text" formControlName="calle">
              <small *ngIf="addressError" class="small-red">Dirección es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Número exterior:</label>
              <input class="input-form form-control" type="text" formControlName="numeroExterior">
              <small *ngIf="addressError" class="small-red">Dirección es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Número interior:</label>
              <input class="input-form form-control" type="text" formControlName="numeroInterior">
              <!-- <small *ngIf="addressError" class="small-red">Dirección es un campo requerido.</small> -->
            </div>
            <div class="col-12 col-sm-6">
              <label>Regimen fiscal (Persona física):</label>
              <select class="input-form form-control" formControlName="regimenFiscal">
                <option *ngFor="let item of regime" [value]="item.id">
                  {{item.name}}
                </option>
              </select>
              <small *ngIf="neighborhoodError" class="small-red">Colonia es un campo requerido.</small>
            </div>

            <div class="row">
              <div class="col-12 col-sm-6">
                <button class="button-white" (click)="redirect()">Regresar</button>
              </div>
              <div class="col-12 col-sm-6">
                <button class="button button-orange" type="submit">Emitir</button>
              </div>
            </div>
          </div>

          <!-- PS -->
          <div class="row" *ngIf="provider == 'ps'">
            <div class="col-12 col-sm-4">
              <label>Nombre:</label>
              <input class="select-form form-control" type="text" formControlName="cte_nombre" style="background-color: #ececec;" readonly>
              <small *ngIf="nameError" class="small-red">Nombre es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Apellido paterno:</label>
              <input class="select-form form-control" type="text" formControlName="cte_apepat" style="background-color: #ececec;" readonly>
              <small *ngIf="lastError" class="small-red">Apellido paterno es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Apellido materno:</label>
              <input class="select-form form-control" type="text" formControlName="cte_apemat" style="background-color: #ececec;" readonly>
              <small *ngIf="secondError" class="small-red">Apellido materno es un campo requerido.</small>
            </div>
            <!-- <div class="col-12 col-sm-2">
              <label>Género:</label>
              <input *ngIf="formIssue.value.sexo == 'F'" class="select-form form-control" type="text" placeholder="Femenino" style="background-color: #ececec;" readonly>
              <input *ngIf="formIssue.value.sexo == 'M'" class="select-form form-control" type="text" placeholder="Masculino" style="background-color: #ececec;" readonly>
              <button class="button-female" type="button" [ngStyle]="{'color': colorFemale, 'background-color': backgroundFemale}" (click)="getSex('F')">Femenino</button>
              <button class="button-male" type="button" [ngStyle]="{'color': colorMale, 'background-color': backgroundMale}" (click)="getSex('M')">Masculino</button>
              <small *ngIf="sexError" class="small-red">Género es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-3">
              <label>Fecha de nacimiento:</label>
              <input class="select-form form-control" type="date" formControlName="fecha_nacimiento" style="background-color: #ececec;" readonly>
              <small *ngIf="addressError" class="small-red">Fecha es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Correo electrónico:</label>
              <input class="select-form form-control" type="email" formControlName="email" style="background-color: #ececec;" readonly>
              <small *ngIf="emailError" class="small-red">Correo es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-3">
              <label>Número de teléfono:</label>
              <input class="select-form form-control" type="phone" formControlName="tel_num" style="background-color: #ececec;" readonly>
              <small *ngIf="phoneError" class="small-red">Teléfono es un campo requerido.</small>
            </div> -->

            <div class="col-12 col-sm-4">
              <label>Colonia:</label>
              <select class="form-control" formControlName="dir_col_id">
                <option *ngFor="let item of neighborhoods" [value]="item.clave">
                  {{item.nombre}}
                </option>
              </select>
              <small *ngIf="neighborhoodError" class="small-red">Colonia es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Calle y número:</label>
              <input class="select-form form-control" type="text" formControlName="dir_calle_num">
              <small *ngIf="addressError" class="small-red">Dirección es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>RFC(con homoclave):</label>
              <input class="select-form form-control" type="text" formControlName="rfc">
              <small *ngIf="rfcError" class="small-red">RFC es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Numero de serie:</label>
              <input class="select-form form-control" type="text" formControlName="aut_no_serie">
              <small *ngIf="serieError" class="small-red">Serie es un campo requerido.</small>
            </div>
            <div class="col-12 col-sm-4">
              <label>Número de placas:</label>
              <input class="select-form form-control" type="text" formControlName="aut_placas">
              <small *ngIf="platesError" class="small-red">Placas es un campo requerido.</small>
            </div>
            
            <div class="row">
              <div class="col-12 col-sm-6">
                <button class="button-white" (click)="redirect()">Regresar</button>
              </div>
              <div class="col-12 col-sm-6">
                <button class="button button-orange" type="submit">Emitir</button>
              </div>
            </div>
          </div>


        </form>
      </div>
    </div>
  </div>
</div>

<div *ngIf="loader" class="container-load">
  <img class="image-load" src="../../../assets/images/loading_2.gif" alt="">
</div>