import { Injectable } from '@angular/core';
import * as Rx from "rxjs";


@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  constructor() { }

  private subject!: Rx.BehaviorSubject<MessageEvent>;
  
  public connect(url:string): Rx.BehaviorSubject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(url);
      console.log("Successfully connected #1: " + url);
    }else{      
      this.subject = this.create(url);
      console.log("Successfully connected #2: " + url);
    }
    return this.subject;
  }

  public close(url:string) {
    let ws = new WebSocket(url);
    console.log('on closing WS',ws);
    let observable = Rx.Observable.create((obs: Rx.Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    })
    let observer = {
      next: (data: Object) => {
        if (ws.readyState === WebSocket.OPEN) {
        }
      }
    };
    // this.subject =  Rx.BehaviorSubject<MessageEvent>;
    this.subject.subscribe(data => {
      console.log(data); //Hello
    });
    return Rx.BehaviorSubject.create(observer, observable);
  }
  private create(url:string): Rx.BehaviorSubject<MessageEvent> {
    let ws = new WebSocket(url);
    
    let observable = Rx.Observable.create((obs: Rx.Observer<MessageEvent>) => {
      ws.onmessage = obs.next.bind(obs);
      ws.onerror = obs.error.bind(obs);
      ws.onclose = obs.complete.bind(obs);
      return ws.close.bind(ws);
    });
    let observer = {
      next: (data: Object) => {
        if (ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      }
    };

    // ws.close()
    return Rx.BehaviorSubject.create(observer, observable);
  }
}
