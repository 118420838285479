import { isLoweredSymbol } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { PromiseComponent } from '../promise/promise.component';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  showInformation = false;
  showError = false;
  cards:any = [];

  title = '';
  comment = {};
  subtitles:any = [];
  comments:any = [];
  img:any = [];
  idBlog:string;



  constructor(
    public _api: PromiseComponent,
    private router: Router, private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.goToAdmin();
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.idBlog = params.get('id');
      if(this.idBlog !==null) {
        this.goToInformation(this.idBlog);
      }
    });
  }

  goToAdmin(){
    this._api.login('superuser_gpi', 'Gpisuperuser2021').then(response=>{
      if(response['token_jwt']){
        this.getCards();
      }
      if(response['error']){
      }
    }).catch(error=>{
      console.log(error);
    })
  };

  getCards(){
    this._api.get_cards().then(response =>{
      if(response){
        for (let i = 0; i < response['length']; i++) {
          let day_start = response[i]['created_date'].substr(8, 2);
          let month_start = response[i]['created_date'].substr(5, 2);
          let year_start = response[i]['created_date'].substr(0, 4);

          day_start = day_start.length == 1 ? '0' + day_start : day_start;
          month_start = month_start.length == 1 ? '0' + month_start : month_start;

          response[i]['created_date'] = day_start + '/' + month_start + '/' + year_start;
          this.cards.push(response[i]);
        }
      }
    }).catch(error=>{
      console.log(error);
    })
  };
  
  goToInformation(value) {
    switch (value){
      case '8-preguntas-antes-de-comprar-un-seguro-de-auto':
        this.img = '../../../assets/images/blog/blog_8preguntas.jpg';
        this.title = '8 preguntas antes de comprar un seguro de auto';
        this.subtitles = '<p>Para muchos, el adquirir un auto es un motivo de orgullo, ' +
        ' pero suelen olvidar la responsabilidad y prevención que esto conlleva. Por ello, te contamos ' +
        'todo lo que debes preguntarte antes de adquirir un seguro para tu auto.</p>' +
        '<p><b>¿Por qué quiero una póliza de seguro?</b></p>' +
        '<p>Es importante definir cuáles son las razones que te motivan a adquirir este tipo de  '+
        ' póliza de seguro para determinar qué protección es la que necesitas. Es decir, podrías sólo ' +
        'buscar cumplir un requisito vial, proteger tu auto de cualquier daño que pueda sufrir o el que ' +
        'tu auto pueda ser robado. </p>' +
        '<p><b>¿Cuáles son las principales coberturas que hay en el mercado?</b></p>' +
        '<p>Pese a existir variantes en los tipos de coberturas, hay tres básicas: Responsabilidad  ' +
        'Civil, Limitada y Amplia.</p>' +
        '<p>Un Seguro de Responsabilidad Civil (RC) únicamente cubre los daños ocasionados a terceros ' +
        'a partir de un siniestro donde tu auto fue el causante. Aunque está limitado en los montos a ' +
        'cubrir. La cobertura Limitada lleva implícita la de RC, además de asistencia legal y vial que dan ' +
        'al usuario mayor protección. Además, contempla la protección en caso de robo total.</p>' +
        '<p>La cobertura cubre los daños a terceros, en caso de robo total y los daños materiales que sufra ' +
        'tu unidad, además de todo lo que cubren las dos coberturas anteriores.</p>' +
        '<p><b>¿Cómo puedo saber qué me ofrece cada compañía de seguros?</b></p>' +
        '<p>Una vez que sabes el tipo de cobertura que deseas contratar, deberás empezar a comparar. Contacta ' +
        'un agente de seguros, pregunta a tus conocidos sobre sus pólizas o ingresa a la página web de cada ' +
        'aseguradora. En GPI te ayudamos a cotizar con las mejores aseguradoras para que tengas el comparativo ' +
        'y elijas la mejor opción, además, te brindamos asesoría en caso de que tengas dudas, para garantizar ' +
        'que tu seguro cubre todas tus necesidades. </p>' +
        '<p><b>¿Qué calificación tienen las aseguradoras ante Condusef?</b></p>' +
        '<p>Recuerda que contratar los servicios de una aseguradora es dejar en sus manos la protección y asistencia ' +
        'ante un percance. De ahí que la misma Condusef ha hecho distintos estudios para medir la eficiencia ante ' +
        'los usuarios. En la estadística oficial se puede observar el índice de reclamaciones y multas, hasta las ' +
        'calificaciones a los ajustadores. </p>' +
        '<p><b>¿Cuál es la suma asegurada en daños a terceros?</b></p>' +
        '<p>Es uno de los elementos principales que debes checar en cualquier póliza. Se refiere al monto total que' +
        'la aseguradora está dispuesta a cubrir en caso de un siniestro. </p>' +
        '<p><b>¿Qué tipo de asistencia vial ofrecen?</b></p>' +
        '<p>Uno de los aspectos más importante a considerar es la asistencia vial. Algunas pólizas limitan el número ' +
        'de eventos, como es el caso de grúas. Incluso otras sólo cubren hasta cierta distancia alrededor del ' +
        'domicilio registrado.</p>'
        '<p><b>¿Cuáles son los deducibles que establece el contrato?</b></p>' +
        '<p>Uno de los elementos para encontrar un seguro de auto a bajo precio es tener un deducible alto, sin embargo, ' +
        'puede no ser lo mejor. El deducible es la cantidad no cubierta por la aseguradora, es decir, lo que deberás ' +
        'cubrir de tu bolsa. Por ejemplo, en caso de robo total, es posible que la compañía establezca un deducible ' +
        'del 10%, lo que significa que deberás cubrir esa parte en efectivo para hacerte acreedor a la indemnización.</p>' +
        '<p>La recomendación es que revises y compares los porcentajes requeridos por las compañías y tomes lo que más te ' +
        'convenga. Recuerda que el robo total y daños a terceros son las principales circunstancias donde estás obligado a pagar un deducible.</p>' +
        '<p><b>¿Qué tipo de exclusiones tiene?</b></p>' +
        '<p>Este apartado debe ser muy claro antes de contratar, pues brinda certeza al propietario de las condiciones y ' +
        'alcances de su póliza. Aunque siempre hay restricciones, es obligación de las compañías manifestarlas, y del ' +
        'usuario, indagar sobre ellas. </p>' +
        '<p>Esperamos haberte ayudado para adquirir el seguro de auto ideal para ti. Recuerda que nos puedes contactar para ' +
        'cotizar tu seguro pues en GPI trabajamos con las mejores aseguradoras de México. Además, te podemos brindar un ' +
        'descuento de hasta 50%, asesoría personalizada, atención de primera y la confianza de estar respaldado por un bróker ' +
        'con más de 50 años de experiencia.</p>' +
        '<p>Contáctanos si te interesa un seguro al correo contacto@grupogpi.mx  y ¡Hazlo sin miedo! </p>';
        
        break;
      case 'Que-es-y-para-que-sirve-un-seguro-de-autos':
        this.img = '../../../assets/images/blog/blog_seguro.jpg';
        this.title = '¿Qué es y para qué sirve un seguro de autos?';
        this.subtitles = '<p>Regularmente cuando buscamos cómo asegurar nuestro auto, las preguntas más comunes que nos hacemos son ' +
        '¿cuánto cuesta? o ¿qué abarca un seguro de autos? Para responder lo anterior, lo primero que debemos saber ' +
        'es qué es un seguro de auto, su cobertura y los tipos de seguro que hay.  ' +
        '<p>Un seguro de auto es un contrato entre el propietario de un vehículo y una aseguradora cuyo objetivo es <b>cubrir ' +
        'económicamente los riesgos asociados a la conducción y tenencia de dicho vehículo</b>, como choques, robo, ' +
        'daños y otros servicios, así como protección a tus pasajeros y a terceros afectados en un accidente</p>' +
        '<p><b>Características de un seguro de auto</b></p>' +
        '<p>Si bien los seguros de auto pueden variar, tienen en común ciertas características como:</p>' +
        '<p><b>Cobertura.</b>Al momento de adquirir tu póliza de seguros tienes la opción de escoger qué ' +
        'tipo de cobertura elegir. </p>' +
        '<p><b>Pago máximo. </b>Este se refiere al valor total que cubrirá tu póliza de seguros. Por ejemplo, si los daños  ' +
        'por un accidente en tu automóvil equivalen a 500 mil pesos y tu póliza de seguro tiene un tope de 400 mil, ' +
        'los 100 mil pesos restantes deberán correr por tu cuenta. </p>' +
        '<p><b>Términos de reclamación.</b>Aquí hablamos del procedimiento que deberás realizar para que la aseguradora '+
        'realice la indemnización correspondiente.</p>' + 
        '<p><b>Tipos de seguro de auto</b></p>' +
        '<p>Los tipos de seguro de auto pueden clasificarse según su cobertura: amplia, limitada o de Responsabilidad Civil.</p>' +
        '<p><b>Responsabilidad Civil</b></p>' +
        '<p>En México existe por ley un seguro básico que es de carácter obligatorio y que cubre la Responsabilidad Civil del ' +
        'conductor o del propietario del vehículo en caso de perjudicar a algún tercero usando dicho auto. A partir ' +
        'del 1° de enero de 2019, es obligatorio tener seguro de Responsabilidad Civil al transitar por Carreteras ' +
        'Federales en nuestro país. </p>' +
        '<p>Este tipo de seguro cubre los daños a terceros afectados por valor de 100,000 pesos y 50,000 pesos adicionales por perjuicios ' +
        'físicos a terceros ocupantes del vehículo involucrado en el accidente. </p>' +
        '<p>Por tanto, el seguro de responsabilidad civil no incluye coberturas contra daños del vehículo, asistencia vial o ' +
        'gastos legales.</p>' +
        '<p><b>Cobertura Limitada</b></p>' +
        '<p>Incluye las coberturas más básicas, pero tiene como prioridad cubrir los daños causados a terceros debido a algún accidente y ' +
        'el robo del vehículo asegurado.</p>' +
        '<p>Este tipo de seguro no cubre los daños al vehículo asegurado.</p>' +
        '<p><b>Cobertura Amplia</b></p>' +
        '<p>Cubre, no solo, la responsabilidad civil del conductor o del propietario, sino además la pérdida total del vehículo, la ' +
        'asistencia en carretera, los gastos médicos a los ocupantes, los gastos legales y algunos adicionales que estén incluidos en la póliza</p>' +
        'Recuerda que con un seguro de auto tu vehículo estará protegido y tú estarás más tranquilo. En GPI podemos cotizar tu Seguro con las mejores ' +
        'aseguradoras de México y te brindamos beneficios extras como un descuento de hasta 50%, asesoría personalizada, atención de ' +
        'primera y la confianza de estar respaldado por un bróker con más de 50 años de experiencia. Contáctanos ' +
        'si te interesa un seguro y ¡Hazlo sin miedo!';
        break;
      default:
        break;
      }
      this.showInformation = true;
      //this.router.navigate(['blog2/ejemplo']);
      
    
    /*this._api.get_subcards(param.id).then(response=>{
      if(response){
        this.subtitles = response;
      }
    }).catch(error=>{
      console.log(error);
    })
    this._api.get_comments(param.id).then(response=>{
      if(response){
        this.comments = response;
      }
    }).catch(error=>{
      console.log(error);
    })*/
  }

  saveComment(){
    this.comment['author'] = 'Usuario';
    this.comment['priority'] = 0;
    this._api.save_comments(this.title['id'], this.comment).then(responses=>{
      this.comment['text'] = '';
      this._api.get_comments(this.title['id']).then(response=>{
        if(response){
          this.comments = response;
        }
      }).catch(error=>{
        console.log(error);
      })
    }).catch(error=>{
      console.log(error);
    })
  }

  return(param){
    this.showInformation = false;
    this.router.navigate(['/' + param]);
  }

}
