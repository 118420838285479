import { Component, OnInit } from '@angular/core';
import { PromiseComponent } from '../promise/promise.component';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.css']
})
export class PolicyComponent implements OnInit {

  showAuto;
  showMedical;
  showLife;
  showHouse;
  showDamage;
  showTransport;
  showPlatform;

  contact = {
    nombre: '',
    email: '',
    contacto: '',
    asunto: '',
    mensaje: ''
  }

  showAlertError;
  showAlertEmail;
  showAlertSuccess;

  constructor(
    public _api: PromiseComponent
  ) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.showAuto = false;
    this.showMedical = false;
    this.showLife = false;
    this.showHouse = false;
    this.showDamage = false;
    this.showTransport = false;
    this.showPlatform = false;

    this.showAlertError = false;
    this.showAlertEmail = false;
    this.showAlertSuccess = false;
  }

  goToCard(value){
    switch (value) {
      case 1:
        this.showAuto = true;
        this.showMedical = false;
        this.showLife = false;
        this.showHouse = false;
        this.showDamage = false;
        this.showTransport = false;
        this.showPlatform = false;
        break;
      case 1.5:
        this.showAuto = true;
        this.showMedical = false;
        this.showLife = false;
        this.showHouse = false;
        this.showDamage = false;
        this.showTransport = false;
        this.showPlatform = true;
        break;
      case 2:
        this.showAuto = false;
        this.showMedical = true;
        this.showLife = false;
        this.showHouse = false;
        this.showDamage = false;
        this.showTransport = false;
        this.showPlatform = false;
        break;
      case 3:
        this.showAuto = false;
        this.showMedical = false;
        this.showLife = true;
        this.showHouse = false;
        this.showDamage = false;
        this.showTransport = false;
        this.showPlatform = false;
        break;
      case 4:
        this.showAuto = false;
        this.showMedical = false;
        this.showLife = false;
        this.showHouse = true;
        this.showDamage = false;
        this.showTransport = false;
        this.showPlatform = false;
        break;
      case 5:
        this.showAuto = false;
        this.showMedical = false;
        this.showLife = false;
        this.showHouse = false;
        this.showDamage = true;
        this.showTransport = false;
        this.showPlatform = false;
        break;
      case 6:
        this.showAuto = false;
        this.showMedical = false;
        this.showLife = false;
        this.showHouse = false;
        this.showDamage = false;
        this.showTransport = true;
        this.showPlatform = false;
        break;
      default:
        this.showAuto = false;
        this.showMedical = false;
        this.showLife = false;
        this.showHouse = false;
        this.showDamage = false;
        this.showTransport = false;
        this.showPlatform = false;
        break;
    }
  }

  validateEmail(email){
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  sendContact(){
    if(this.contact.nombre && this.contact.email && this.contact.contacto && this.contact.asunto && this.contact.mensaje){
      if(!this.validateEmail(this.contact['email'])){
        this.showAlertEmail = true;
        return;
      }
      this._api.send_contact(this.contact).then(response=>{
        if(response['status'] == 'sent'){
          this.showAlertSuccess = true;
        }
        else if(response['status'] == 500){
          this.showAlertError = true;
        }
      }).catch(error=>{
        console.log(error);
      })
    }
    else{
      this.showAlertError = true;
    }
  };

  cancel(){
    this.showAlertError = false;
    this.showAlertEmail = false;
    this.showAlertSuccess = false;
  }

}
