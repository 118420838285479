<app-header></app-header>
<div class="container-main">
  <div class="container" *ngIf="!showInformation">
    <div class="row">
      <div class="col-12 form-group">
        <p class="title-purple">Blog</p>
        <hr>
      </div>
      <div class="col-12 form-group">
        <div >
          <div class="row">
            <div class="col-12 col-sm-4 form-group">
              <!-- <img class="img-guide" [src]="item.image" alt=""> -->
              <img class="image-small" src="../../../assets/images/blog/blog_8preguntas.jpg" alt="">
            </div>
            <div class="col-12 col-sm-8 form-group">
              <p class="title-orange">8 preguntas antes de comprar un seguro de auto</p>
              <p>Para muchos, el adquirir un auto es un motivo de orgullo, pero suelen olvidar 
                la responsabilidad y prevención que esto conlleva. Por ello, te contamos todo 
                lo que debes preguntarte antes de adquirir un seguro para tu auto. </p>                
                <a [routerLink]="['/blog', '8-preguntas-antes-de-comprar-un-seguro-de-auto']" class="button-purple" id ="buttonBlog">
                  <button class="button-purple" id ="buttonBlog" >Seguir leyendo</button>
                </a>
            </div>
            <div class="col-12 form-group">
              <hr>
            </div>
          </div>
        </div>
        <div >
          <div class="row">
            <div class="col-12 col-sm-4 form-group">
              <!-- <img class="img-guide" [src]="item.image" alt=""> -->
              <img class="image-small" src="../../../assets/images/blog/blog_seguro.jpg" alt="">
            </div>
            <div class="col-12 col-sm-8 form-group">
              <p class="title-orange">¿Qué es y para qué sirve un seguro de autos?</p>
              <p>Regularmente cuando buscamos cómo asegurar nuestro auto, las preguntas 
                más comunes que nos hacemos son ¿cuánto cuesta? o ¿qué abarca un seguro de autos? Para 
                responder lo anterior, lo primero que debemos saber es qué es un seguro de auto, 
                su cobertura y los tipos de seguro que hay.  </p>
                <a [routerLink]="['/blog', 'Que-es-y-para-que-sirve-un-seguro-de-autos']" class="button-purple" id ="buttonBlog">
                  <button class="button-purple" id ="buttonBlog" >Seguir leyendo</button>
                </a>
            </div>
            <div class="col-12 form-group">
              <hr>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="showInformation">
    <div class="row">
      <div class="col-12 form-group">
        <img class="image-info" src="{{this.img}}" alt="">
        <!-- <img class="img-guide" [src]="title.image" alt=""> -->
      </div>
    </div>
    <div>
      <div class="row">
        <div class="col-12 form-group">
          <span class="title-blog"><div [innerHTML]="title"></div></span>
        </div>
        <div class="col-12 form-group">
          <span class="subtitle-blog"> <div [innerHTML]="subtitles"> </div></span>     
        </div>
      </div>
    </div>
    <!--div class="row">
      <div class="col-12 form-group">
        <p class="subtitle-blog">Comentarios</p>
      </div>
      <div class="col-12 col-sm-9 form-group">
        <input class="form-control" type="text" [(ngModel)]="comment.text">
      </div>
      <div class="col-12 col-sm-3 form-group">
        <button class="button-green" (click)="saveComment()">Enviar Comentario</button>
      </div>
    </div-->
    <div *ngFor="let comm of comments">
      <div class="row">
        <div class="col-12 form-group">
          <img class="image-blog" src="https://miurabox.s3.amazonaws.com/cas/noavatar.png" alt="">
          <p class="user-blog">{{ comm.author }}</p>
          <div class="container-blog">
            <p class="text-blog">{{ comm.text }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 form-group">
        <button class="button-purple" (click)="return('blog')">Regresar</button>
      </div>
    </div>
  </div>
</div>