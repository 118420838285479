import { RouterModule, Routes } from "@angular/router";

import { HomeComponent } from './pages/home/home.component';
import { BlogComponent } from "./pages/blog/blog.component";

const appRoutes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: '**', redirectTo: 'cotizador', pathMatch: 'full' },
  { path: '', redirectTo: 'cotizador', pathMatch: 'full' },
  { path: 'blog', component: BlogComponent }
]

export const APP_ROUTES = RouterModule.forRoot(appRoutes, {useHash: true});