<div class="container-main">
  <div class="container-orange">
    <div class="container">
      <div class="row">
        <div class="col-12 form-group">
          <p class="text-white">Mi GPI</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-grey">
    <div class="container">
      <div class="row">
        <div class="col-12 form-group">
          <p class="text-purple-footer">AVISO DE PRIVACIDAD</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-white">
    <div class="container">
      <div class="row">
        <div class="col-12 form-group">
          <p class="text-cards">
            GPI Agente de Seguros y de Fianzas S.A. de C.V. (en adelante GPI) con domicilio en Av. Antea No. 1032, Nivel 10, Jurica, C.P. 76100, Querétaro, Qro. México; 
            con teléfono de contacto (56) 4226 1114 y con correo electrónico de contacto contacto@grupogpi.mx, con website grupogpi.mx  con horario de atención a clientes 
            y público en general de las 09:00 a 14:00 de Lunes a Viernes. En cumplimiento a la Ley Federal de Protección de Datos Personales en Posesión de los Particulares 
            y su respectivo reglamento, hacen de su conocimiento que recaba y trata sus datos personales para las finalidades que establece este Aviso de Privacidad, enfocados 
            objetivamente en un sentido amplio y abstracto a regular su tratamiento legítimo, controlado e informado, a efecto de garantizar la privacidad y el derecho a la 
            autodeterminación informativa de las personas.
          </p>
          <p class="text-cards">
            GPI recaba sus datos personales de manera directa por medios electrónicos ya sea a través del “chat” o por correo electrónico y/o por teléfono, y de manera indirecta 
            por medio de transferencias que le hacen llegar sus empresas filiales y/o sucursales y/o terceros, empleados y/o subordinados. Manifestando que la información descrita 
            en el párrafo que antecede se fija como datos de contacto a nuestro departamento de comercialización para tal efecto.
          </p>
          <p class="text-cards">
            Sus datos personales serán utilizados objetivamente para las siguientes finalidades:
          </p>
          <p class="text-cards">
            CLIENTES. – Para realizar su registro como cliente, identificarlo en cualquier tipo de relación jurídica o de negocios, proveer, procesar, completar y darle seguimiento 
            a los servicios requeridos por Usted y dar cumplimiento a las obligaciones contraídas, elaborar la factura correspondiente que ampara el servicio prestado, para realizar 
            la cobranza por los dichos servicios y para ayudarlo a entrar en contacto con nuestros medios internos y externos de administración y operación.
          </p>
          <p class="text-cards">
            PROVEEDORES.– Para realizar su registro como proveedor, identificarlo en cualquier tipo de relación jurídica, para realizar todas las cuestiones internas necesarias 
            relacionadas con la relación comercial vigente que nos une, incluyendo cuestiones de pagos y preparar pedidos , solicitudes de servicios, cotizaciones y cualquier actividad 
            contractual y pre-contractual que pudiese existir.
          </p>
          <p class="text-cards">
            Sus datos personales serán tratados únicamente por el tiempo necesario a fin de cumplir con las finalidades descritas en el presente Aviso de Privacidad. Para las finalidades 
            señaladas en el presente Aviso de Privacidad, podemos recabar sus datos personales cuando Usted nos proporcione directamente dicha información de una manera y forma directa y 
            por cualquier medio de comunicación conocido en la actualidad.
          </p>
          <p class="text-cards">
            Datos Personales que recabamos de clientes.- Para las finalidades señaladas en el presente aviso de privacidad, recabamos sus datos personales de manera directa cuando Usted 
            nos los proporciona al solicitar nuestros servicios, cuando se registra como cliente nuevo, cuando actualiza sus datos previamente registrados, cuando celebramos contratos y 
            cuando nos da información con objeto que le extendamos la factura correspondiente. Los datos que obtenemos para este fin son: nombre, domicilio, Registro Federal de Contribuyentes, 
            Cédula de Identificación Fiscal, copias de identificaciones oficiales, teléfono, dirección de correo electrónico, referencias de redes sociales, entre otros que sirvan para lo 
            mencionado en este Aviso de Privacidad de Datos.
          </p>
          <p class="text-cards">
            Datos personales que recabamos de proveedores.- Para las finalidades señaladas en el presente aviso de privacidad, recabamos sus datos personales de forma directa cuando Usted 
            mismo nos los proporciona al registrarse como proveedor, cuando nos ofrece sus productos o servicios, cuando celebramos contratos, cuando nos da información bancaria de sus 
            cuentas para efecto de realizar pagos. Los datos que obtenemos para este fin son: nombre, domicilio, Registro Federal de Contribuyentes, Cédula de Identificación Fiscal, copias 
            de identificaciones oficiales teléfono, dirección de correo electrónico, referencias de redes sociales, entre otros que sirvan para lo mencionado en este Aviso de Privacidad de 
            Datos. Le informamos que en nuestra empresa no se solicitan datos personales sensibles y que si fuera el caso, por medio de este aviso de privacidad nos otorga el más amplio 
            consentimiento para tal efecto. Usted tiene el derecho de acceder a sus datos personales en nuestro poder y conocer los detalles del tratamiento de los mismos, así́ como a 
            rectificarlos en caso de ser inexactos o incompletos, cancelarlos cuando considere que no se requieren para alguna de las finalidades señaladas en el presente aviso de privacidad, 
            estén siendo utilizados para finalidades no consentidas o haya finalizado la relación contractual o de servicio u oponerse al tratamiento de los mismos para los fines específicos. 
            Los datos personales y/o personales sensibles que nos proporcione cualquier persona física, incluyendo sin limitar clientes, proveedores, postulantes y/o interesados a diferentes 
            puestos vacantes, empleados y/o usuarios a GPI, tendrán el uso que en forma enunciativa pero no limitativa se describe a continuación: para identificar, ubicar, comunicar, contactar, 
            enviar o suministrar información y/o productos y/o servicios solicitados, reclutamiento, selección y administración de personal, ante en nuestras bases de datos en general, para 
            el cumplimiento de obligaciones derivadas de una relación jurídica o contractual, laboral, mercantil o de cualquier otra índole, Nos comprometemos a que todos los datos obtenidos 
            serán tratados bajo las más estrictas medidas de seguridad que garanticen su confidencialidad. Su información personal será utilizada única y exclusivamente para los fines que 
            aquí́ se describen adicionalmente si fuera el caso para dar seguimiento a comentarios, quejas, sugerencias, reclamaciones que Usted presente en relación a nuestros servicios y 
            productos, mantener controles estadísticos y administrativos, y en su caso, para fines de publicidad y prospección comercial, otorgando desde este momento su más amplio consentimiento. 
            En caso que usted no desee que se traten sus datos personales para recibir publicidad y ofertas, usted nos lo puede hacer saber por los medios de contacto señalados en este aviso 
            de privacidad.
          </p>
          <p class="text-cards">
            De conformidad con el artículo 8 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares, requerimos de su consentimiento expreso para el tratamiento de sus 
            datos personales de carácter “financiero y patrimonial”. Si usted no manifiesta su oposición para el tratamiento de sus datos personales de carácter financiero y patrimonial, se 
            entenderá que nos otorga su consentimiento expreso para ello mediante el presente aviso de privacidad. De cualquier forma, nos comprometemos a no tratar estos datos para finalidades 
            diversas de las apuntadas en este Aviso de Privacidad, salvo en el caso de las excepciones previstas en el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión 
            de los Particulares.
          </p>
          <p class="text-cards">
            Le informamos que sus datos personales pueden ser transferidos por GPI y tratados dentro y fuera del país a personas distintas de esta empresa, por cualquiera de los medios ya 
            mencionados en el presente aviso de privacidad. En ese sentido, su información puede ser compartida para dar seguimiento a los comentarios, consultas, quejas y reclamaciones que usted 
            presente, así́ como para mantener controles administrativos y estadísticos y para fines de publicidad y prospección comercial, otorgando desde este momento su más amplio consentimiento 
            para tal efecto. Si usted no manifiesta su oposición para que sus datos personales sean transferidos a terceros para estos fines, se entenderá de igual manera que nos otorga su 
            consentimiento para ello. Es importante señalar que los terceros a los que se pudiese hacer transferencia de sus datos personales se comprometen en los mismos términos del presente 
            Aviso de Privacidad y cumplen con las medidas de seguridad y confidencialidad correspondientes. De cualquier manera, nos comprometemos a no transferir su información personal a terceros 
            sin su consentimiento, salvo en el caso de las excepciones previstas en el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, así́ como a 
            realizar esta transferencia en los términos que fija esa Ley y el presente aviso de privacidad.
          </p>
          <p class="text-cards">
            Toda vez que el tratamiento y la transferencia de sus datos personales son indispensables para cumplir las finalidades expuestas en el presente Aviso de Privacidad, su limitación podría 
            impedirnos cumplir con ellas y proporcionarle los servicios de calidad que Usted espera de nosotros. Usted tiene derecho de acceder a sus datos personales que poseemos y a los detalles 
            de su tratamiento, así́ como a rectificarlos en caso de ser inexactos o incompletos, o bien, cancelarlos cuando considere que no se requieren para alguna de las finalidades señaladas en 
            el presente Aviso de Privacidad; estén siendo utilizados para finalidades no consentidas o haya finalizado la relación contractual o de servicio, o bien, oponerse al tratamiento de los 
            mismos para fines específicos (Derechos ARCO por medio de la solicitud correspondiente).
          </p>
          <p class="text-cards">
            Para el ejercicio de los mencionados DERECHOS ARCO (Artículo 28 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares. Del Ejercicio de los Derechos de Acceso, 
            Rectificación, Cancelación y Oposición) Deberá presentar la solicitud correspondientes en nuestras oficinas y/o por cualquier medio de contacto y/o localización mencionado al inicio de 
            este aviso de privacidad.
          </p>
          <p class="text-cards">
            La solicitud que presente para el ejercicio de los Derechos Arco deberá indicar su nombre y el domicilio o correo electrónico en el que se desea recibir toda la información relacionada 
            con el trámite, además de estar acompañada del o los documentos con los que se acredite su identidad o la personalidad de su representante legal. Así́ mismo, le agradeceremos incluir una 
            descripción clara y precisa de los datos respecto de los que se busca ejercer alguno de los derechos “ARCO” y de cualquier otro elemento que nos permita identificar o localizar los datos 
            personales en cuestión. Cuando se trate de “rectificación” de sus datos personales, deberá indicarnos también cual es la modificación exacta que busca realizar y presentar la documentación 
            que soporte la petición.
          </p>
          <p class="text-cards">
            Le pedimos que considere que por su protección y beneficio, es posible que le solicitemos documentación adicional que nos permita identificar con plenitud los datos que desee acceder, 
            rectificar y/o cancelar, o aquellos respecto de los cuales Usted desee oponerse a su tratamiento. Asimismo, le informamos que es posible que no podamos cancelar o bloquear sus datos 
            personales de conformidad con lo dispuesto por la Ley Federal de Protección de Datos Personales en Posesión de Particulares y su Reglamento. Una vez recibida su solicitud completa y 
            debidamente integrada, nuestro Departamento comercial le comunicara la debida respuesta en un plazo máximo de 20 días hábiles. Si resultare procedente, dentro de los 15 días siguientes 
            podrá hacer efectivo su derecho solicitado. En caso de que GPI deba hacer entrega de documentación como resultado del derecho ejercido, lo hará a través de copias que le serán enviadas 
            por correo electrónico o entregadas en el domicilio que para ello haya señalado en su solicitud dentro del plazo antes mencionado. Si bien el ejercicio de los Derechos Arco y la entrega 
            de información es gratuita, GPI se reserva el derecho a cobrar los gastos de envío y costos de fotocopiado.
          </p>
          <p class="text-cards">
            Usted puede en cualquier momento revocar el consentimiento que nos ha otorgado para impedir total o parcialmente el tratamiento de sus datos personales, en el entendido de que estos son 
            necesarios para los fines antes descritos y su limitación podría dificultarnos el cumplimiento de las finalidades para las cuales los recabamos y la prestación de los servicios que usted 
            espera de nosotros.
          </p>
          <p class="text-cards">
            En caso de que desee revocar su consentimiento al tratamiento de sus datos personales, deberá presentar su solicitud ante el Departamento correspondiente de nuestra empresa tal y como se 
            describe en el presente aviso de privacidad acompañada de copia de su identificación oficial para acreditar que es el titular de los datos personales (en su caso, la acreditación de su 
            representante legal), y señalar el correo electrónico o domicilio el que se desea recibir la información relacionada con el trámite, una descripción clara y precisa de los datos personales 
            respecto de los cuales requiere revocar su consentimiento y los datos que nos permitan notificarle la resolución recaída a su solicitud. Le notificaremos la resolución recaída a su solicitud 
            dentro de los 15 días hábiles siguientes a la presentación de su solicitud, siempre que esta se encuentre completa y debidamente integrada.
          </p>
          <p class="text-cards">
            En cualquier momento GPI puede hacer modificaciones o actualizaciones al presente Aviso de Privacidad para la atención de novedades legislativas, políticas internas o nuevos requerimientos 
            para la administración de quejas y reclamaciones de los clientes.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>