import { RouterModule, Routes } from "@angular/router";
import { PagesComponent } from './pages.component';
import { HomeComponent } from '../pages/home/home.component';
import { PolicyComponent } from '../pages/policy/policy.component';
import { SuretyComponent } from '../pages/surety/surety.component';
import { CreditsComponent } from "./credits/credits.component";
import { BlogComponent } from '../pages/blog/blog.component';
import { ContactComponent } from '../pages/contact/contact.component';
import { AboutComponent } from '../pages/about/about.component';
import { CarComponent } from './car/car.component';
import { CarComparisonComponent } from './car-comparison/car-comparison.component';
import { CarIssueComponent } from './car-issue/car-issue.component';
import { HouseComponent } from './house/house.component';
import { MedicalComponent } from './medical/medical.component';
import { MigpiComponent } from "./migpi/migpi.component";
import { AdministratorComponent } from './administrator/administrator.component';
import { DocsComponent } from './docs/docs.component';
import { CarPrintComponent } from "./car-print/car-print.component";

const pagesRoutes: Routes = [
  { 
    path: '', 
    component: PagesComponent,
    children: [
      { path: 'inicio', component: HomeComponent },
      { path: 'polizas', component: PolicyComponent },
      { path: 'fianzas', component: SuretyComponent },
      { path: 'capital', component: CreditsComponent },
      { path: 'blog', component: BlogComponent },
      { path: 'blog/:id', component: BlogComponent },
      { path: 'contacto', component: ContactComponent },
      { path: 'nosotros', component: AboutComponent },
      { path: 'cotizador-auto', component: CarComponent },
      { path: 'comparador-auto', component: CarComparisonComponent },
      { path: 'emision-auto', component: CarIssueComponent },
      { path: 'impresion-auto', component: CarPrintComponent },
      { path: 'cotizador-hogar', component: HouseComponent },
      { path: 'cotizador-gastos', component: MedicalComponent },
      { path: 'mi-gpi', component: MigpiComponent },
      { path: 'administrador', component: AdministratorComponent },
      { path: '', redirectTo: '/inicio', pathMatch: 'full' },
      { path: 'docs', component: DocsComponent },

    ]
  },
]

export const PAGES_ROUTES = RouterModule.forChild( pagesRoutes );