<div class="container-main">
  <div class="container-orange">
    <div class="container">
      <div class="row">
        <div class="col-12 form-group">
          <p class="text-white">¡Paga a 12 Meses sin intereses!</p>
          <p class="text-white-small">Casi todos los seguros participan</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-grey">
    <div class="container">
      <div class="row">
        <div class="col-12 form-group">
          <p class="text-purple"><img class="image-title" src="../../../assets/images/policy/img_seccion_seguros.png" alt=""> ¡Hazlo sin miedo!</p>
          <p class="text-purple-small">Precios justos <span class="point-orange">·</span> Atención de primera <span class="point-orange">·</span> Respaldo total</p>
        </div>
      </div>
    </div>
  </div>
  <div class="container-white">
    <div class="container">
      <div class="row">
        <div class="col-12 form-group">
          <p class="text-cards">Elige el Seguro que te interesa para más información...</p>
        </div>
        <div class="col-12 col-sm-6 col-md-4 form-group">
          <div class="card" *ngIf="!showAuto">
            <div class="card-head">
              <img class="image-card" src="../../../assets/images/policy/ico_seguro_auto.svg" alt="">
              <p class="title-card">Seguro de Auto</p>
            </div>
            <div class="card-body">
              <p class="text-auto">¡Hasta 50% <br> de descuento!</p>
              <p class="plus-orange">+</p>
              <p class="text-auto">Cómodas formas <br> de pago</p>
              <button class="button-white" (click)="goToCard(1)">Más información</button>
              <button class="button-orange" routerLink="/cotizador-auto">¡Solicitar cotización!</button>
            </div>
          </div>
          <div class="card" *ngIf="!showPlatform && showAuto">
            <div class="card-purple">
              <p class="title-card-white">Seguro de Auto</p>
              <p class="subtitle-card-white-auto">Cotizamos tu auto con las mejores <br> compañías aseguradoras</p>
              <ul>
                <li><p class="text-card-white">Elige tu deducible.</p></li>
                <li><p class="text-card-white">Hasta 50% de descuento.</p></li>
                <li><p class="text-card-white">Cómodas formas de pago.</p></li>
                <li><p class="text-card-white">Elige tu cobertura: Amplia, Limitada o Básica.</p></li>
                <li><p class="text-card-white">Asesoría y apoyo total de nuestros asesores.</p></li>
              </ul>
              <p class="text-card-orange-auto">¡Solicita tu cotización!</p>
              <div class="container-grey-auto">
                <p class="text-auto-grey"><span class="plus-orange">√</span> Seguro para autos de Plataforma</p>
                <p class="text-auto-grey-thin">Seguro en flotilla con los precios MÁS bajos <br> (Uber, Didi, Cabify, etc.)</p>
                <button class="button-orange" (click)="goToCard(1.5)">Más información</button>
              </div>
              <img class="image-card-purple" src="../../../assets/images/policy/ico_cerrar.svg" (click)="goToCard(9)" alt="">
            </div>
          </div>
          <div class="card" *ngIf="showPlatform && showAuto">
            <div class="card-body">
              <img class="image-card" src="../../../assets/images/policy/ico_seguro_auto_plataforma.svg" alt="">
              <p class="title-card-purple">Seguro para autos <br> de Plataforma</p>
              <p class="text-auto">¡Tu auto, tú y tus acompañantes <br> están protegidos!</p>
              <p class="text-auto-thin">Tenemos planes especiales de Seguro para autos  <br> de plataforma. Seguro para Uber, Didi, Cabify <br> Taxis y más.</p>
              <p class="text-auto-responsive">¡Además, con <br> GRANDES DESCUENTOS!</p>
              <p class="text-card-orange-auto">¡Cotiza con nosotros!</p>
              <img class="image-card-purple" src="../../../assets/images/policy/ico_cerrar.svg" (click)="goToCard(9)" alt="">
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 form-group">
          <div class="card" *ngIf="!showMedical">
            <div class="card-head">
              <img class="image-card" src="../../../assets/images/policy/ico_seguro_medicos.svg" alt="">
              <p class="title-card">Seguro de Gastos Médicos Mayores</p>
            </div>
            <div class="card-body">
              <p class="text-medical">Atención en <br> los mejores hospitales</p>
              <p class="text-medical-thin">sin que te cueste <br> un ojo de la cara</p>
              <button class="button-white" (click)="goToCard(2)">Más información</button>
              <button class="button-orange" data-bs-toggle="modal" data-bs-target="#exampleModal">¡Solicitar cotización!</button>
            </div>
          </div>
          <div class="card" *ngIf="showMedical">
            <div class="card-purple">
              <p class="title-card-white">Seguro de Gastos <br> Médicos Mayores</p>
              <p class="subtitle-card-white">La mejor atención médica <br> al mejor precio</p>
              <ul>
                <li><p class="text-card-white">Individual o Colectivo.</p></li>
                <li><p class="text-card-white">Cotización con las mejores aseguradoras del país.</p></li>
                <li><p class="text-card-white">Cobertura en caso de accidente, emergencias, COVID y más.</p></li>
                <li><p class="text-card-white">Atención en los mejores hospitales.</p></li>
                <li><p class="text-card-white">Facilidades en forma de pago.</p></li>
              </ul>
              <p class="text-card-orange-medical">¡Solicita tu cotización!</p>
              <img class="image-card-purple" src="../../../assets/images/policy/ico_cerrar.svg" (click)="goToCard(9)" alt="">
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 form-group">
          <div class="card" *ngIf="!showLife">
            <div class="card-head">
              <img class="image-card" src="../../../assets/images/policy/ico_seguro_vida.svg" alt="">
              <p class="title-card">Seguro de Vida</p>
            </div>
            <div class="card-body">
              <p class="text-life">Tranquilidad para ti <br> y tu familia</p>
              <p class="text-life-thin">Cobertura a tu medida</p>
              <button class="button-white" (click)="goToCard(3)">Más información</button>
              <button class="button-orange" data-bs-toggle="modal" data-bs-target="#exampleModal">¡Solicitar cotización!</button>
            </div>
          </div>
          <div class="card" *ngIf="showLife">
            <div class="card-purple">
              <p class="title-card-white">Seguro de Vida</p>
              <p class="subtitle-card-white">Coberturas:</p>
              <ul>
                <li><p class="text-card-white">Individual o Grupo.</p></li>
                <li><p class="text-card-white">Fallecimiento.</p></li>
                <li><p class="text-card-white">Invalidez total y permanente.</p></li>
                <li><p class="text-card-white">Pérdidas orgánicas.</p></li>
                <li><p class="text-card-white">Muerte accidental.</p></li>
              </ul>
              <p class="text-card-white-life">Contrata tu Seguro de Vida y brinda tranquilidad <br> a tu familia</p>
              <br>
              <p class="text-card-orange-life">¡Solicita tu cotización!</p>
              <img class="image-card-purple" src="../../../assets/images/policy/ico_cerrar.svg" (click)="goToCard(9)" alt="">
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 form-group">
          <div class="card" *ngIf="!showHouse">
            <div class="card-head">
              <img class="image-card" src="../../../assets/images/policy/ico_seguro_hogar.svg" alt="">
              <p class="title-card">Seguro de Hogar</p>
            </div>
            <div class="card-body">
              <p class="text-house-thin">Para tu casa <br> o departamento.</p>
              <p class="text-house-thin">Desde los vidrios hasta <br> tu mascota están protegidos.</p>
              <p class="text-house">Desde $1,500 anuales</p>
              <button class="button-white" (click)="goToCard(4)">Más información</button>
              <button class="button-orange" data-bs-toggle="modal" data-bs-target="#exampleModal">¡Solicitar cotización!</button>
            </div>
          </div>
          <div class="card" *ngIf="showHouse">
            <div class="card-purple">
              <p class="title-card-white">Seguro de Hogar</p>
              <p class="subtitle-card-white-house">Tu patrimonio está protegido al 100</p>
              <p class="text-card-white-house-thin">√ Diferentes planes de protección:</p>
              <p class="text-card-white-house">Precio anual desde $1,500 pesos</p>
              <p class="text-card-white">Las mejores coberturas:</p>
              <ul>
                <li><p class="text-card-white">Edificio</p></li>
                <li><p class="text-card-white">Contenidos</p></li>
                <li><p class="text-card-white">Personal doméstico</p></li>
                <li><p class="text-card-white">Mascotas</p></li>
                <li><p class="text-card-white">Cristales</p></li>
                <li><p class="text-card-white">Robo</p></li>
                <li><p class="text-card-white">Responsabilidad civil</p></li>
                <li><p class="text-card-white">Asistencias</p></li>
              </ul>
              <p class="text-card-white">¡Y mucho más</p>
              <p class="text-card-orange-house" data-bs-toggle="modal" data-bs-target="#exampleModal">¡Solicita tu cotización!</p>
              <img class="image-card-purple" src="../../../assets/images/policy/ico_cerrar.svg" (click)="goToCard(9)" alt="">
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 form-group">
          <div class="card" *ngIf="!showDamage">
            <div class="card-head">
              <img class="image-card" src="../../../assets/images/policy/ico_seguro_empresarial.svg" alt="">
              <p class="title-card">Seguro Empresarial</p>
            </div>
            <div class="card-body">
              <p class="text-damage">Protege a tu negocio</p>
              <p class="text-damage-thin">de los daños y riesgos cubiertos</p>
              <button class="button-white" (click)="goToCard(5)">Más información</button>
              <button class="button-orange" data-bs-toggle="modal" data-bs-target="#exampleModal">¡Solicitar cotización!</button>
            </div>
          </div>
          <div class="card" *ngIf="showDamage">
            <div class="card-purple">
              <p class="title-card-white">Seguro Empresarial</p>
              <p class="subtitle-card-white">Coberturas:</p>
              <ul>
                <li><p class="text-card-white">Incendio en edificio.</p></li>
                <li><p class="text-card-white">Incendio de contenidos.</p></li>
                <li><p class="text-card-white">Fenómenos hidrometeorológicos.</p></li>
                <li><p class="text-card-white">Terremoto y/o erupción volcánica.</p></li>
                <li><p class="text-card-white">Robo con violencia.</p></li>
                <li><p class="text-card-white">Dinero y/o valores.</p></li>
                <li><p class="text-card-white">Responsabilidad civil.</p></li>
                <li><p class="text-card-white">Cristales, etc.</p></li>
              </ul>
              <p class="text-card-orange-damage" data-bs-toggle="modal" data-bs-target="#exampleModal">¡Solicita tu cotización!</p>
              <img class="image-card-purple" src="../../../assets/images/policy/ico_cerrar.svg" (click)="goToCard(9)" alt="">
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6 col-md-4 form-group">
          <div class="card" *ngIf="!showTransport">
            <div class="card-head">
              <img class="image-card" src="../../../assets/images/policy/ico_seguro_transporte.svg" alt="">
              <p class="title-card">Seguro de Transporte de Mercancías</p>
            </div>
            <div class="card-body">
              <p class="text-transport-thin">Asegura tu carga marítima, <br> aérea o terrestre en caso <br> de robo, accidente o más.</p>
              <p class="text-transport">¡Todo el contenido <br> está asegurado!</p>
              <button class="button-white" (click)="goToCard(6)">Más información</button>
              <button class="button-orange" data-bs-toggle="modal" data-bs-target="#exampleModal">¡Solicitar cotización!</button>
            </div>
          </div>
          <div class="card" *ngIf="showTransport">
            <div class="card-purple">
              <p class="title-card-white">Seguro de Transporte de Mercancías</p>
              <p class="subtitle-card-white-transport">¡Tu transporte y su carga <br> están protegidos!</p>
              <p class="subtitle-card-white">Coberturas:</p>
              <ul>
                <li><p class="text-card-white">Riesgos ordinarios de tránsito (colisión, volcadura, incendio, explosión, etc.)</p></li>
                <li><p class="text-card-white">Robo total.</p></li>
                <li><p class="text-card-white">Robo parcial.</p></li>
                <li><p class="text-card-white">Huelas y alborotos populares.</p></li>
                <li><p class="text-card-white">Maniobra de carga y descarga.</p></li>
              </ul>
              <p class="text-card-white">Y más...</p>
              <p class="text-card-orange-transport">¡Solicita tu cotización!</p>
              <img class="image-card-purple" src="../../../assets/images/policy/ico_cerrar.svg" (click)="goToCard(9)" alt="">
            </div>
          </div>
        </div>
        <div class="col-12 form-group">
          <p class="text-purple-footer">Cotiza y contrata en minutos</p>
          <p class="text-cards">¡Hacemos que suceda!</p>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container-modal">
          <p class="title-modal">Solicita tu cotización</p>
          <div>
            <p class="text-contact">Nombre <span class="red">*</span></p>
            <input class="form-control" type="text" [(ngModel)]="contact.nombre">
          </div>
          <div>
            <p class="text-contact">Correo electrónico <span class="red">*</span></p>
            <input class="form-control" type="text" [(ngModel)]="contact.email">
          </div>
          <div>
            <p class="text-contact">Número de contacto <span class="red">*</span></p>
            <input class="form-control" type="text" [(ngModel)]="contact.contacto">
          </div>
          <div>
            <p class="text-contact">Asunto <span class="red">*</span></p>
            <input class="form-control" type="text" [(ngModel)]="contact.asunto">
          </div>
          <div>
            <p class="text-contact">Mensaje <span class="red">*</span></p>
            <textarea class="form-control" rows="4" [(ngModel)]="contact.mensaje"></textarea>
          </div>
          <div>
            <button class="button-contact" (click)="sendContact()">Enviar</button>
          </div>
          <!-- <p class="text-modal">Llama o escríbenos al <span class="text-modal-bold"> cel. 442 191 0757</span> <br> o envía un correo a <span class="text-modal-bold">contacto@grupogpi.mx</span></p> -->
          <p class="text-modal">Llama a <span class="text-modal-bold"> tel. (442) 628 2596</span></p> <p class="text-modal">o mándanos WhatsApp al <span class="text-modal-bold">(442) 239 2830</span></p>
          <p> También puedes enviar un correo a <span class="text-modal-bold">contacto@grupogpi.mx</span></p>
          <p class="text-modal-paddind">Nuestros asesores con gusto te atenderán</p>
          <button class="button-modal" data-bs-dismiss="modal">Aceptar</button>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-alert" *ngIf="showAlertError">
  <div class="container-modal-message">
    <p class="title-modal-orange">Ha ocurrido un error</p>
    <p>Por favor vuelve a intentarlo, <br> es necesario llenar todos los campos</p>
    <button class="button-modal" (click)="cancel()">Aceptar</button>
  </div>
</div>
<div class="container-alert" *ngIf="showAlertEmail">
  <div class="container-modal-message">
    <p class="title-modal-orange">Ha ocurrido un error</p>
    <p>El correo electrónico tiene un formato inválido</p>
    <button class="button-modal" (click)="cancel()">Aceptar</button>
  </div>
</div>
<div class="container-alert" *ngIf="showAlertSuccess">
  <div class="container-modal-message">
    <p class="title-modal-purple">Gracias, tu mensaje se ha enviado</p>
    <p>Pronto, un asesor estará en contacto contigo</p>
    <button class="button-modal" (click)="cancel()">Aceptar</button>
  </div>
</div>