<div class="container-main">
  <div class="container">
    <div class="row">
      <div class="col-12 form-group">
        <p class="title-purple">Administrador</p>
      </div>
    </div>
    <div class="row" *ngIf="!showInformation">
      <div class="col-12 col-sm-4 form-group">
        <div class="col-12 form-group">
          <p class="text-form">Usuario:</p>
          <input class="form-control" type="text" [(ngModel)]="user">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Contrseña:</p>
          <input class="form-control" type="password" [(ngModel)]="password">
        </div>
        <div class="col-12 form-group">
          <button class="button-orange" (click)="goToAdmin()">Ingresa</button>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="showInformation">
      <div class="col-12 col-sm-4 form-group">
        <button class="button-purple" (click)="logout()">Cerrar Sesión</button>
      </div>
    </div>
    <div class="row" *ngIf="showInformation">
      <div class="col-12 col-sm-6 form-group">
        <p class="text-form">Categoría:</p>
        <select class="form-control" [(ngModel)]="filter.category" (change)="getActions()">
          <option *ngFor="let item of categories" [value]="item.name">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="col-12 col-sm-6 form-group">
        <p class="text-form">Acción:</p>
        <select class="form-control" [(ngModel)]="filter.action" (change)="getItems()" [disabled]="!filter.category || filter.category == 0">
          <option *ngFor="let item of actions" [value]="item.value">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="col-12 col-sm-6 form-group" *ngIf="showItem">
        <p class="text-form">Artículo:</p>
        <select class="form-control" [(ngModel)]="filter.item" (change)="getInformation(filter.item)">
          <option *ngFor="let item of items" [value]="item.id">
            {{ item.title }}
          </option>
        </select>
      </div>
      <div class="col-12 form-group" *ngIf="showItem">
        <button class="button-purple" (click)="getAction()" [disabled]="!filter.item || filter.item == 0">Continuar</button>
      </div>
      <div class="col-12 form-group">
        <hr>
      </div>
    </div>
    <div *ngIf="showAdd">
      <div class="row">
        <div class="col-12 form-group">
          <span class="title-orange">Nuevo Artículo</span>
        </div>
      </div>
      <div class="row">
        <div class="col-12 form-group">
          <p class="text-form">Título:</p>
          <input class="form-control" type="text" [(ngModel)]="title.title">
        </div>
        <div class="col-12 form-group">
          <p class="text-form">Texto</p>
          <textarea class="form-control" [(ngModel)]="title.text"></textarea>
        </div>
      </div>
      <div *ngFor="let item of subtitles; let i = index;">
        <div class="row">
          <div class="col-12 form-group">
            <p class="text-form">Subtítulo:</p>
            <input class="form-control" type="text" [(ngModel)]="item.title">
          </div>
          <div class="col-12 form-group">
            <p class="text-form">Texto</p>
            <textarea class="form-control" [(ngModel)]="item.text"></textarea>
          </div>
          <div class="col-12 col-sm-4 form-group">
            <button class="button-purple" (click)="deleteSubtitle(item, i)" [disabled]="subtitles.length == 1">Eliminar subtitulo</button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-4 form-group">
          <button class="button-orange" (click)="addSubtitle()">Agregar Subtítulo</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-4 form-group">
          <button class="button-green" *ngIf="filter.action == 1" (click)="save()">Guardar</button>
        </div>
        <div class="col-12 form-group">
          <button class="button-purple" *ngIf="filter.action == 2" (click)="update()">Actualizar</button>
        </div>
        <div class="col-12 form-group">
          <input
          style="display: none"
          type="file" (change)="onFileChanged($event); preview(fileInput.files)"
          #fileInput>
          <button class="button-orange" (click)="fileInput.click()">Seleccionar imágen</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 form-group">
          <span style="color:red;" *ngIf="message">{{message}}</span>
          <img [src]="imgURL" *ngIf="imgURL">
          <button *ngIf="imgURL" class="button-orange" (click)="onUpload()">Cargar imágen</button>
        </div>
      </div>
    </div>
  </div>
</div>