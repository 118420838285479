<div class="container-main">
  <div class="container">
    <div class="row">
      <div class="col-12 form-group">
        <p class="text-purple">¿Por qué asegurarte con nosotros?</p>
      </div>
      <div class="col-12 col-sm-4 form-group">
        <img class="image-section" src="../../../assets/images/about/img_seccion_gpi.png" alt="">
      </div>
      <div class="col-12 col-sm-8 form-group">
        <p class="text-grey"><span class="text-grey-bold">Somos un bróker, legado de tres generaciones, <br> que durante más de 50 años,</span> nos hemos dedicado <br> a la actividad aseguradora, con el fin de brindar protección <br> y tranquilidad a nuestros clientes.</p>
      </div>
    </div>
  </div>
</div>
<div class="container-hands">
  <div class="container">
    <div class="row">
      <div class="col-12 form-group">
        <p>Trabajamos únicamente con <br> las mejores aseguradoras de México, <br> avaladas por la CONDUSEF.</p>
        <img class="image-gpi" src="../../../assets/images/about/ico_gpi.svg" alt="">
      </div>
    </div>
  </div>
</div>
<div class="container-orange">
  <div class="container">
    <div class="row">
      <div class="col-12 form-group">
        <p>El tamaño y experiencia de GPI como bróker, <br> hace que tengamos mejores condiciones, <br> precios y atención de las aseguradoras <br> para nuestros clientes.</p>
        <img class="image-gpi" src="../../../assets/images/about/ico_gpi.svg" alt="">
      </div>
    </div>
  </div>
</div>
<div class="container-meet">
  <div class="container">
    <div class="row">
      <div class="col-12 form-group">
        <p> Nuestro equipo está capacitado para brindar <br> la mejor atención y apoyo cuando <br> más lo necesitas.</p>
        <img class="image-gpi" src="../../../assets/images/about/ico_gpi.svg" alt="">
      </div>
    </div>
  </div>
</div>
<div class="container-purple">
  <div class="container">
    <div class="row">
      <div class="col-12 form-group">
        <p class="text-white-purple">¡Hazlo sin miedo!</p>
        <p class="text-white">Cotiza y contrata tu Seguro</p>
      </div>
    </div>
  </div>
</div>
